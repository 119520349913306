export interface StockType {
    id: string;
    stock_id: string;
    movement_id?: string;
    code: string;
    status: string;
    type: string;
    name: string;
    price: number;
    amount: number;
    entrance: number;
    output: number;
    unit_name: string;
    validity: string | null;
    description: string | null;
    created: string;
    updated: string | null;
}

export interface StockPayloadType {
    code: string;
    status: string;
    name: string;
    price: number | string;
    amount?: number;
    type: string;
    unit_name: string;
    validity: string | null;
    description: string | null;
}

export interface StockMovementType {
    stock_id: string;
    production_id: string | null;
    is_culture: boolean;
    stock: StockType;
    amount: number;
    type: string;
    created: string;
    updated: string | null;
}

export interface StockMovementPayloadType {
    stock_id: string;
    production_id?: string;
    is_culture: boolean;
    amount: string;
    type: string;
}

export enum UnitEnum {
    "BALE" = "BALE",
    "BAG" = "BAG",
    "BOX" = "BOX",
    "CENTIMETER" = "CENTIMETER",
    "CUBIC_METER" = "CUBIC",
    "GRAM" = "GRAM",
    "KILOGRAM" = "KILOGRAM",
    "LITER" = "LITER",
    "LOT" = "LOT",
    "MILLILITER" = "MILLILITER",
    "METER" = "METER",
    "MILLIMETER" = "MILLIMETER",
    "PACKAGE" = "PACKAGE",
    "PALLET" = "PALLET",
    "PAIR" = "PAIR",
    "ROLL" = "ROLL",
    "TON" = "TON",
    "UNIT" = "UNIT",
}

export enum StockStatus {
    "IN_STOCK" = "IN_STOCK",
    "OUT_STOCK" = "OUT_STOCK",
    "ON_HOLD" = "ON_HOLD",
    "RESERVED" = "RESERVED",
    "DEFECT" = "DEFECT",
    "BLOCKED" = "BLOCKED",
    "REMOVED" = "REMOVED",
}

export enum StockTypes {
    "CULTURE" = "CULTURE",
    "PRODUCT" = "PRODUCT",
    "MACHINE" = "MACHINE",
    "OTHER" = "OTHER",
}