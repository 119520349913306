import { Module } from "vuex";
import type { MessageType } from "@/common/types/messages";

const messageModule: Module<any, any> = {
    state() {
        const messages: MessageType[] | null = null;
        return {
            messages_total: 0,
            messages,
        };
    },
    getters: {
        messages: (state) => state.messages,
        messages_total: (state) => state.messages_total,
    },
    mutations: {
        SET_MESSAGES: (state, data) => {
            state.messages = data;
        },
        SET_MESSAGES_TOTAL: (state, data) => {
            state.messages_total = data;
        },
    },
};

export default messageModule;
