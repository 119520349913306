import clientAxios from "../client";

import { formatQueryString } from "@/common/utils/common";

import type { QueryType } from "@/common/types/common";

export async function avatarGet(
    owner_id: string,
    query?: QueryType
) {

    const queryString = formatQueryString(query);

    return await clientAxios.get(
        `common/avatar/${owner_id}${queryString}`
    );
}

export async function avatarAdd(
    owner_id: string,
    file: string
) {
    return await clientAxios.post(
        `common/avatar/${owner_id}`,
        { file }
    );
}

export async function avatarPut(
    owner_id: string,
    avatar_id: string,
    file: string
) {
    return await clientAxios.put(
        `common/avatar/${owner_id}/${avatar_id}`,
        { file }
    );
}

export async function avatarDel(
    owner_id: string,
    avatar_id: string,
) {
    return await clientAxios.del(
        `common/avatar/${owner_id}/${avatar_id}`,
    );
}
