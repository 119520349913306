import { common } from "./common";
import { error } from "./error";
import { user } from "./user";
import { plan } from "./plan";
import { stock } from "./stock";

export const language = {
    translations: {
        common,
        error,
        user,
        plan,
        stock,
    },
};