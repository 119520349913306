<script setup lang="ts">
import { ref, computed } from "vue";
import * as tooltip from "@zag-js/tooltip";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { bsonId } from "@/common/utils/common";

defineProps<{
  disabled?: boolean;
  position?: string;
}>();

const [state, send] = useMachine(
  tooltip.machine({
    id: bsonId(),
    positioning: {
      placement: "bottom",
    },
  })
);
const api = computed(() => tooltip.connect(state.value, send, normalizeProps));
</script>

<template>
  <div class="base-tooltip">
    <div
      ref="ref"
      v-bind="api.triggerProps"
      @mouseover="send('OPEN')"
      @mouseout="send('CLOSE')"
    >
      <slot name="trigger"></slot>
    </div>
    <template v-if="!disabled && $slots.tooltip">
      <div v-if="api.open" v-bind="api.positionerProps">
        <div v-bind="api.contentProps">
          <slot name="tooltip"></slot>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.base-tooltip {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;

  .trigger {
    display: flex;
    height: min-content;
  }
}

[data-part="content"] {
  background: white;
  color: #000;
  box-shadow: 2px 3px 4px var(--shadow);
  padding-block: 0.2rem;
  padding-inline: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  border: 1px solid #e7e7e7;
}
</style>
