import { UnitEnum, StockStatus, StockTypes } from "@/common/types/stock";

export const stock = {
    labels: {
    },
    enum: {
        status: {
            [StockStatus.IN_STOCK]: "In stock",
            [StockStatus.OUT_STOCK]: "Out of stock",
            [StockStatus.ON_HOLD]: "On Hold",
            [StockStatus.RESERVED]: "Reserved",
            [StockStatus.DEFECT]: "Defect",
            [StockStatus.BLOCKED]: "Blocked",
            [StockStatus.REMOVED]: "Removed",
        },
        types: {
            [StockTypes.CULTURE]: "Culture",
            [StockTypes.PRODUCT]: "Product",
            [StockTypes.MACHINE]: "Machine",
            [StockTypes.OTHER]: "Other",
        },
        units_name: {
            [UnitEnum.BALE]: "Bale",
            [UnitEnum.BAG]: "Bag",
            [UnitEnum.BOX]: "Box",
            [UnitEnum.CENTIMETER]: "Centimeter",
            [UnitEnum.CUBIC_METER]: "Cubic Meter",
            [UnitEnum.GRAM]: "Gram",
            [UnitEnum.KILOGRAM]: "Kilogram",
            [UnitEnum.LITER]: "Liter",
            [UnitEnum.LOT]: "Lot",
            [UnitEnum.MILLILITER]: "Milliliter",
            [UnitEnum.METER]: "Meter",
            [UnitEnum.MILLIMETER]: "Millimeter",
            [UnitEnum.PACKAGE]: "Package",
            [UnitEnum.PALLET]: "Pallet",
            [UnitEnum.PAIR]: "Pair",
            [UnitEnum.ROLL]: "Roll",
            [UnitEnum.TON]: "Ton",
            [UnitEnum.UNIT]: "Unit",
        },
        units_short: {
            [UnitEnum.BALE]: "bale",
            [UnitEnum.BAG]: "bg",
            [UnitEnum.BOX]: "bx",
            [UnitEnum.CENTIMETER]: "cm",
            [UnitEnum.CUBIC_METER]: "m³",
            [UnitEnum.GRAM]: "g",
            [UnitEnum.KILOGRAM]: "kg",
            [UnitEnum.LITER]: "L",
            [UnitEnum.LOT]: "lot",
            [UnitEnum.MILLILITER]: "ml",
            [UnitEnum.METER]: "m",
            [UnitEnum.MILLIMETER]: "mm",
            [UnitEnum.PACKAGE]: "pkg",
            [UnitEnum.PALLET]: "pal",
            [UnitEnum.PAIR]: "pr",
            [UnitEnum.ROLL]: "rol",
            [UnitEnum.TON]: "t",
            [UnitEnum.UNIT]: "un",
        },
    },
    texts: {},
};
