import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/auth/login' },
  ],
});

const requireComponent = require.context('@/pages', true, /\.vue$/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = String(fileName)
    .replace(/^\.\/(.*)\.\w+$/, '$1')
    .replace(/\[(.*?)\]/g, ':$1')
    .replace("/index", "");

  router.addRoute({
    path: `/${componentName.toLowerCase()}`,
    component: componentConfig.default || componentConfig,
  });
});

export default router;
