<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import store from "@/store";

import { toast } from "@/common/utils/toast";

import { teamAdd, teamDel, teamList, teamUpdate } from "@/common/api/rest/team";

import type { TeamType, TeamPayloadType } from "@/common/types/team";

import { PanelTheme } from "@/common/themes";
import { teamForm } from "@/components";
import {
  baseConfirm,
  baseModal,
  baseTable,
  svgIcon,
} from "@/common/components";

const { t, tm } = useI18n();
const route = useRoute();

const tableHeader = computed(() => [
  { key: "name", label: t("common.labels.name") },
  { key: "type", label: t("common.labels.type") },
  { key: "email", label: t("common.labels.email") },
  { key: "status", label: t("common.labels.status") },
]);

const accountID = computed(() => route.params.id as string);

const tableData = ref<TeamType[]>([]);
const payload = ref<TeamPayloadType | null>(null);
const selected = ref<TeamType | null>(null);

const limit = ref<number>(10);
const page = ref<number>(0);
const pages = ref<number>(0);
const search = ref<string>("");
const searched = ref<number>(0);
const total = ref<number>(0);

const isEdit = ref(false);
const limited = ref(false);
const showDelConfirm = ref(false);
const showModalForm = ref(false);

function fetchData() {
  teamList(accountID.value, {
    limit: limit.value,
    page: page.value,
    search: search.value,
  })
    .then((response) => {
      const { success, data } = response;

      if (!success) {
        toast({ message: data.message, type: "error" });
        return;
      }

      tableData.value = data.list;
      page.value = data.infos.page;
      pages.value = data.infos.pages;
      total.value = data.infos.total;
      searched.value = data.infos.count;
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function opemForm(data?: TeamType) {
  if (data) {
    selected.value = data;
    isEdit.value = true;
  }

  if (limited.value && !isEdit.value) {
    toast({ message: "Limite atingido", type: "info" });
    return;
  }

  showModalForm.value = true;
}

function closeForm() {
  selected.value = null;
  isEdit.value = false;
  showModalForm.value = false;
}

function submitAdd() {
  store.commit("SET_LOADING", true);

  teamAdd(accountID.value, payload.value)
    .then((response) => {
      const { success, data } = response;
      fetchData();
      closeForm();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function submitEdit() {
  if (!selected.value) return;

  store.commit("SET_LOADING", true);

  teamUpdate(accountID.value, selected.value.team_id, payload.value)
    .then((response) => {
      const { success, data } = response;
      fetchData();
      closeForm();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function submitPayload() {
  if (!isEdit.value) {
    submitAdd();
  } else {
    submitEdit();
  }
}

function removeItem(data: TeamType) {
  showDelConfirm.value = true;
  selected.value = data;
}

function removeItemCancel() {
  showDelConfirm.value = false;
  selected.value = null;
}

function removeItemConfirm() {
  if (!selected.value) return;

  store.commit("SET_LOADING", true);

  teamDel(accountID.value, selected.value.team_id)
    .then((response) => {
      const { success, data } = response;
      fetchData();
      removeItemCancel();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function searchItem(value: string) {
  search.value = value;
  fetchData();
}

function changePage(value: number) {
  page.value = value;
  fetchData();
}

onMounted(() => {
  fetchData();
});
</script>

<template>
  <panel-theme>
    <section class="team-page">
      <div class="box">
        <div class="header">
          <div class="title">
            <svg-icon icon="group" size="2.5rem" />
            {{ t("common.labels.team") }}
          </div>
        </div>

        <base-table
          :header="tableHeader"
          :data="tableData"
          :limit="limit"
          :page="page"
          :pages="pages"
          :total="total"
          :searched="searched"
          :options="true"
          :edit="true"
          :del="true"
          @change:page="changePage"
          @searching="searchItem"
          @btn:add="opemForm()"
          @btn:edit="opemForm($event)"
          @btn:del="removeItem"
        >
          <template #name="{ data }">
            {{ data.first_name }} {{ data.last_name }}
          </template>

          <template #type="{ data }">
            {{ t(`team.enum.types.${data.type}`) }}
          </template>

          <template #status="{ data }">
            {{
              data.active
                ? t("common.labels.active")
                : t("common.labels.inactive")
            }}
          </template>
        </base-table>
      </div>
    </section>

    <base-modal
      :show="showModalForm"
      :show-button-confirm="true"
      :title="
        isEdit
          ? t('team.labels.edit_team')
          : t('team.labels.add_team').toUpperCase()
      "
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="closeForm()"
      @confirm="submitPayload()"
    >
      <template #content>
        <team-form :data="selected" @update-data="payload = $event" />
      </template>
    </base-modal>

    <base-confirm
      :show="showDelConfirm"
      :title="t('team.labels.team_remove').toUpperCase()"
      :message="t('team.labels.team_remove')"
      @cancel="removeItemCancel"
      @confirm="removeItemConfirm"
    />
  </panel-theme>
</template>

<style lang="scss" scoped>
.team-page {
  display: flex;
  flex-direction: column;
  gap: 1.54rem;

  .header {
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-block-end: 2rem;
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--text-medium);
    }
  }

  .box {
    width: 100%;
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.375rem;
  }
}
</style>
