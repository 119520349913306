export const EvenOrOdd = (value: number) => {
    return value % 2 === 0 ? "even" : "odd";
};

export const setTitle = (value: string) => {
    try {
        document.title = value;
    } catch (_) {
        return;
    }
};

export const setFavIcon = (value: string) => {
    try {
        if (value) {
            const link: any = document.querySelector("link[rel~='icon']");
            if (!link) return;

            link.href = value;
        }
    } catch (_) {
        return;
    }
};

export function getRandomNumber(min: number, max: number) {
    const random = Math.random();

    const range = random * (max - min) + min;

    return Math.floor(range);
}

export function bsonId() {
    const timestamp = Date.now();
    const machineId = Math.random().toString(16).substring(2, 6);
    const processId = Math.floor(Math.random() * 65536).toString(16).substring(0, 4);
    const counter = Math.floor(Math.random() * 16777215).toString(16).substring(0, 6);

    const timestampHex = timestamp.toString(16).padStart(8, '0');
    const machineIdHex = machineId.padStart(6, '0');
    const processIdHex = processId.padStart(4, '0');
    const counterHex = counter.padStart(6, '0');

    const bsonId = timestampHex + machineIdHex + processIdHex + counterHex;

    return bsonId;
}

export function formatQueryString(query?: any) {
    if (!query) return "";

    let queryString = "";
    if (query) {
        queryString += "?";
        queryString += new URLSearchParams(query as never).toString();
    }

    return queryString;
}

export function scrollToHome(key: string) {
    setTimeout(() => {
        const element = document.getElementById(key) || document.getElementsByClassName(key)[0];

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, 200);
}

export function scrollToTop(key: string) {
    setTimeout(() => {
        const element = document.getElementById(key) || document.getElementsByClassName(key)[0];

        if (element) {
            element.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, 200);
}

export function ObjectToBase64(data: any) {
    if (!data) return data;

    try {
        const stringify = JSON.stringify(data);
        const base64 = btoa(stringify);
        return base64;
    } catch (_) {
        return null;
    }
}

export function Base64ToObject(data: string) {
    if (!data) return data;

    try {
        const base64 = atob(data);
        const parsed = JSON.parse(base64);
        return parsed;
    } catch (_) {
        return null;
    }
}
