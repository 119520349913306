<script setup lang="ts">
import debounce from "lodash-es/debounce";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { toast } from "@/common/utils/toast";
import { valueToCurrency } from "@/common/utils/number";
import { getDate } from "@/common/utils/dates";

import { DefaultTheme } from "@/common/themes";

import {
  baseAccordion,
  baseConfirm,
  baseInput,
  baseTextarea,
  svgIcon,
} from "@/common/components";

import {
  userInvoices,
  userAccount,
  userPatchAccount,
} from "@/common/api/rest/user";
import { listPlans, buyPlan } from "@/common/api/rest/plan";

import type { UserAccountType } from "@/common/types/user";
import type { PlanType } from "@/common/types/plan";
import store from "@/store";

type AccordionType = keyof typeof accordions.value;

const { t } = useI18n();

const accordionsDefault = {
  account: false,
  signature: false,
  invoices: false,
  plans: false,
};

const accordions = ref({ ...accordionsDefault });

const account = ref<UserAccountType | null>(null);
const signature = ref<PlanType | null>(null);

const plans = ref<PlanType[]>([]);
const invoices = ref([]);
const selectedPlan = ref<PlanType>();

const accountPayload = ref({ name: "", description: null });

const showBuyPlan = ref(false);

function fetchPlans() {
  listPlans()
    .then((response) => {
      const { success, data } = response;

      if (success) {
        plans.value = data;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function fetchInvoices() {
  userInvoices(store.getters.user.member_id)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        invoices.value = data;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function fetchAccount() {
  userAccount(store.getters.user.member_id).then((response) => {
    const { success, data } = response;
    if (success) {
      store.commit("SET_ACCOUNT", data.owner);
      account.value = data.owner;
      signature.value = data.plan;
      accountPayload.value = {
        name: data.owner?.name || null,
        description: data.owner?.description || null,
      };
    } else {
      toast({ message: data.message, type: "error" });
    }
  });
}

function patchAccount() {
  if (!account.value) return;

  userPatchAccount(account.value.account_id, accountPayload.value)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        toast({ message: data.message, type: "success" });
        fetchAccount();
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function selectPlan(plan: PlanType) {
  showBuyPlan.value = true;
  selectedPlan.value = plan;
}

function cancelBuyPlan() {
  showBuyPlan.value = false;
  selectedPlan.value = undefined;
}

function confirmBuy() {
  showBuyPlan.value = false;

  buyPlan({
    member_id: store.getters.user.member_id,
    plan_id: selectedPlan.value?.plan_id as string,
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        toast({ message: data.message, type: "success" });
        cancelBuyPlan();
        fetchPlans();
        fetchInvoices();
        fetchAccount();
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function toggleAccordion(type: AccordionType, value: boolean) {
  accordions.value = { ...accordionsDefault };

  accordions.value[type] = value;
}

const update = debounce(patchAccount, 1000);

onMounted(() => {
  accordions.value.account = true;
  fetchPlans();
  fetchAccount();
  fetchInvoices();
});
</script>

<template>
  <default-theme>
    <section class="plans">
      <base-accordion
        :open="accordions.account"
        @open="toggleAccordion('account', $event)"
      >
        <template #icon>
          <svg-icon icon="card-2" size="2rem" />
        </template>
        <template #title>
          {{ t("plan.labels.my_account").toUpperCase() }}
        </template>
        <template #content>
          <div class="account">
            <template v-if="account">
              <div class="info">
                <base-input
                  v-model="accountPayload.name"
                  :value="accountPayload.name"
                  :placeholder="t('common.placeholders.name')"
                  :required="true"
                  @input="update()"
                >
                  <template #label>
                    {{ t("common.labels.name") }}
                  </template>
                </base-input>

                <base-textarea
                  v-model="accountPayload.description"
                  :value="accountPayload.description"
                  :placeholder="t('common.placeholders.describe')"
                  @input="update()"
                >
                  <template #label>
                    {{ t("common.labels.description") }}
                  </template>
                </base-textarea>
              </div>
            </template>

            <template v-if="!account">
              <div class="empty">
                {{ t("plan.texts.signature_empty") }}
              </div>
            </template>
          </div>
        </template>
      </base-accordion>

      <base-accordion
        :open="accordions.signature"
        @open="toggleAccordion('signature', $event)"
      >
        <template #icon>
          <svg-icon icon="card-2" size="2rem" />
        </template>
        <template #title>
          {{ t("plan.labels.my_signature").toUpperCase() }}
        </template>
        <template #content>
          <div class="signatures">
            <template v-if="signature">
              <div class="info">
                <div class="line">
                  <label>{{ t("common.labels.name") }}:</label>
                  <strong>{{ signature.name }}</strong>
                </div>
                <div class="line">
                  <label>{{ t("common.labels.price") }}:</label>
                  <strong>{{ valueToCurrency(signature.price) }}</strong>
                </div>
                <div class="line">
                  <label>{{ t("common.labels.users") }}:</label>
                  <strong>{{ signature.users }}</strong>
                </div>
                <div class="line">
                  <label>{{ t("common.labels.status") }}:</label>
                  <strong>{{
                    signature.active
                      ? t("common.labels.active")
                      : t("common.labels.inactive")
                  }}</strong>
                </div>
                <div class="line">
                  <label>{{ t("common.labels.signature") }}:</label>
                  <strong>{{ getDate(account?.signature) }}</strong>
                </div>
                <div class="line">
                  <label>{{ t("common.labels.expiration") }}:</label>
                  <strong>{{ getDate(account?.expiration) }}</strong>
                </div>
              </div>
            </template>

            <template v-if="!signature">
              <div class="empty">
                {{ t("plan.texts.signature_empty") }}
              </div>
            </template>
          </div>
        </template>
      </base-accordion>

      <base-accordion
        :open="accordions.invoices"
        @open="toggleAccordion('invoices', $event)"
      >
        <template #icon>
          <svg-icon icon="card-2" size="2rem" />
        </template>
        <template #title>
          {{ t("plan.labels.my_invoices").toUpperCase() }}
        </template>
        <template #content>
          <div class="invoices">
            <template v-for="(invoice, index) in invoices" :key="index">
              <div class="item">
                <div class="number">
                  {{ index + 1 }}
                </div>
                <div class="info">
                  <div class="line">
                    <label>{{ t("common.labels.status") }}:</label>
                    <strong>{{ invoice?.status }}</strong>
                  </div>
                  <div class="line">
                    <label>{{ t("common.labels.price") }}:</label>
                    <strong>{{ valueToCurrency(invoice.price) }}</strong>
                  </div>
                  <div class="line">
                    <label>{{ t("common.labels.date") }}:</label>
                    <strong>{{ getDate(invoice.created) }}</strong>
                  </div>
                  <div class="line">
                    <label>{{ t("common.labels.expiration") }}:</label>
                    <strong>{{ getDate(invoice.expiration) }}</strong>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="invoices.length === 0">
              <div class="empty">
                {{ t("plan.texts.invoice_empty") }}
              </div>
            </template>
          </div>
        </template>
      </base-accordion>

      <base-accordion
        :open="accordions.plans"
        @open="toggleAccordion('plans', $event)"
      >
        <template #icon>
          <svg-icon icon="plans" size="2rem" />
        </template>
        <template #title>
          {{ t("plan.labels.plans").toUpperCase() }}
        </template>
        <template #content>
          <div class="list-plans">
            <template v-for="(plan, index) in plans" :key="index">
              <div class="plan">
                <div class="name">
                  {{ plan.name }}
                </div>
                <template v-if="!plan.free">
                  <div class="price">
                    Valor: {{ valueToCurrency(plan.price) }}
                  </div>
                </template>
                <div class="resume">
                  {{ plan.description }}
                </div>
                <div class="actions">
                  <button
                    class="btn btn-primary uppercase"
                    :disabled="account.plan_id === plan.plan_id"
                    @click="selectPlan(plan)"
                  >
                    {{ t("common.labels.sign") }}
                  </button>
                </div>
              </div>
            </template>
          </div>
        </template>
      </base-accordion>
    </section>

    <base-confirm
      :show="showBuyPlan"
      title="Adquirir plano"
      message="Você confirma a compra do plano?"
      @confirm="confirmBuy()"
      @cancel="cancelBuyPlan()"
    />
  </default-theme>
</template>

<style lang="scss" scoped>
.plans {
  display: flex;
  flex-direction: column;
  padding-block: 1.57rem;
  padding-inline: 1rem;
  background: #fff;
  border-radius: 0.5rem;

  .account {
    display: flex;
    flex-direction: column;
    padding-block-start: 0.5rem;
    padding-block-end: 1rem;

    .info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .signatures {
    display: flex;
    flex-direction: column;

    .info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .line {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }
    }
  }

  .invoices {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      max-width: calc(100% - 3rem);
      padding-block: 1rem;
      padding-inline: 1.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--border);

      .number {
        width: 4rem;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .line {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
        }
      }
    }
  }

  .list-plans {
    display: flex;
    flex-direction: column;
    gap: 1%;
    padding-block: 1rem;

    .plan {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-block: 1rem;
      padding-inline: 1.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--border);
      margin-block-end: 1%;

      .name {
        font-size: 1.25rem;
        font-weight: bold;
        margin-block-end: 0.25rem;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        margin-block-start: 1.25rem;
      }
    }
  }
}
</style>