export interface ProductionType {
    production_id: string
    stock_id: string | null
    avatar: string | null
    code: string
    status: string
    name: string
    area: number
    area_type: string
    price: number
    start_date: string | null
    culture: any | null
    end_date: string | null
    description: string | null
    finished: boolean
    finished_date: string | null
    canceled: boolean
    candeled_date: string | null
    created: string
    updated: string | null
}

export interface ProductionPayloadType {
    area: string
    area_type: string
    description: string | null
    end_date: string
    name: string
    price: string
    start_date: string
    status: string
}


export enum ProductionAreaEnum {
    ACRES = "ACRES",
    HECTARE = "HECTARE",
    METERS = "METERS",
    KILOMETERS = "KILOMETERS",
}

export enum ProductionStatusEnum {
    PREPARATION = 'PREPARATION',
    PLANTING = "PLANTING",
    HARVEST = "HARVEST",
    NEGOTIATION = 'NEGOTIATION',
    SOLD = 'SOLD',
}

export enum ProductionSearchStatusEnum {
    ALL = 'ALL',
    IN_PROGRESS = "IN_PROGRESS",
    CONCLUDED = "CONCLUDED",
    CANCELED = 'CANCELED',
}