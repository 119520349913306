export function valueToCurrency(
    value: string | number,
    currency = "R$",
    separator = ".",
    dec = ","
) {
    value = parseFloat(String(value)).toFixed(2);

    const [whole, decimal] = value.split(".");

    const formated = whole.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

    const valorFormatado = `${currency} ${formated}${dec}${decimal}`;

    return valorFormatado;
}
