import { Module } from "vuex";
import type {
  UserType,
  UserProfileType,
  UserAccountType,
  UserAffiliateType,
} from "@/common/types/user";

const userModule: Module<any, any> = {
  state() {
    const user: UserType | null = null;
    const profile: UserProfileType | null = null;
    const account: UserAccountType | null = null;
    const affiliate: UserAffiliateType | null = null;

    return {
      logged: false,
      user,
      type: null,
      profile,
      account,
      affiliate,
      avatar: null,
    };
  },
  getters: {
    logged: (state) => state.logged,
    user: (state) => state.user,
    type: (state) => state.type,
    avatar: (state) => state.avatar,
    profile: (state) => state.profile,
    account: (state) => state.account,
    affiliate: (state) => state.affiliate,
  },
  mutations: {
    SET_USER: (state, data) => {
      state.user = data;
    },
    SET_TYPE: (state, data) => {
      state.type = data;
    },
    SET_PROFILE: (state, data) => {
      state.profile = data;
    },
    SET_ACCOUNT: (state, data) => {
      state.account = data;
    },
    SET_AVATAR: (state, data) => {
      state.avatar = data;
    },
    SET_LOGGED: (state, data) => {
      state.logged = data;
    },
  },
};

export default userModule;
