<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import { toast } from "@/common/utils/toast";

import {
  maskCurrencyBR,
  currencyBRtoFloat,
  maskDateBR,
} from "@/common/utils/mask";

import {
  baseInput,
  baseSelect,
  baseTextarea,
  baseDate,
  svgIcon,
} from "@/common/components";

import {
  ProductionPayloadType,
  ProductionType,
  ProductionAreaEnum,
  ProductionStatusEnum,
} from "@/common/types/production";
import moment from "moment";

const { t } = useI18n();

const emit = defineEmits(["update-data"]);

const props = defineProps<{
  data?: ProductionType | null;
  title?: string;
  patch?: boolean;
}>();

const defaultData = {
  area: "0,0",
  area_type: "",
  description: "",
  end_date: "",
  name: "",
  price: "0,0",
  start_date: "",
  status: "",
};

const areaOptions = computed(() =>
  Object.values(ProductionAreaEnum).map((value) => ({
    option: t(`production.enum.area.${value}`),
    value,
  }))
);

const statusOptions = computed(() =>
  Object.values(ProductionStatusEnum).map((value) => ({
    option: t(`production.enum.status.${value}`),
    value,
  }))
);

const payload = ref<ProductionPayloadType>({ ...defaultData });

function update(key: keyof typeof payload.value) {
  if (!props.patch) {
    emit("update-data", payload.value);
    return;
  }

  if (payload.value[key] === props.data[key]) return;

  if (key === "area" || key === "price") {
    emit("update-data", { [key]: currencyBRtoFloat(payload.value[key]) });
    return;
  }

  if (key === "start_date" || key === "end_date") {
    if (payload.value.start_date && payload.value.end_date) {
      const startDate = moment(payload.value.start_date, "YYYY-MM-DD");
      const endDate = moment(payload.value.end_date, "YYYY-MM-DD");

      if (endDate.isBefore(startDate)) {
        payload.value.start_date = "";
        toast({
          message: "A data de PLANTIO não pode ser MAIOR que a de COLHEITA!",
          type: "error",
        });
        return;
      }
    }

    emit("update-data", {
      [key]: payload.value[key],
    });
    return;
  }

  emit("update-data", { [key]: payload.value[key] });
}

watchEffect(async () => {
  if (props.data) {
    const {
      area,
      area_type,
      description,
      name,
      status,
      start_date,
      end_date,
      price,
    } = props.data;

    payload.value = {
      area: maskCurrencyBR(Number.parseFloat(area ?? 0).toFixed(2)).toString(),
      price: maskCurrencyBR(
        Number.parseFloat(price ?? 0).toFixed(2)
      ).toString(),
      area_type,
      description,
      name,
      status,
      start_date,
      end_date,
    };
  }
});
</script>

<template>
  <section class="team-form">
    <template v-if="title">
      <div class="title">
        <svg-icon icon="person" size="2.5rem" />
        {{ title }}
      </div>
    </template>

    <form>
      <base-input
        v-model="payload.name"
        :value="payload.name"
        :placeholder="t('common.placeholders.name')"
        :required="true"
        :max-length="50"
        @blur="update('name')"
      >
        <template #label> {{ t("common.labels.name") }} * </template>
      </base-input>

      <div class="flex flex-row gap-4">
        <base-select
          v-model="payload.status"
          :value="payload.status"
          :options="statusOptions"
          :required="true"
          :is-empty="false"
          @change="update('status')"
        >
          <template #label> {{ t("common.labels.status") }} * </template>
        </base-select>

        <base-date
          v-model="payload.start_date"
          :value="payload.start_date"
          value-mask="YYYY-MM-DD"
          placeholder="--/--/----"
          @update:model-value="update('start_date')"
        >
          <template #label>
            {{ t("production.labels.planting") }}
          </template>
        </base-date>

        <base-date
          v-model="payload.end_date"
          :value="payload.end_date"
          value-mask="YYYY-MM-DD"
          placeholder="--/--/----"
          @update:model-value="update('end_date')"
        >
          <template #label>
            {{ t("production.labels.harvest") }}
          </template>
        </base-date>
      </div>

      <div class="flex flex-row gap-4">
        <base-input
          v-model="payload.area"
          :value="payload.area"
          :placeholder="t('production.placeholders.area')"
          :required="true"
          :mask="maskCurrencyBR"
          @blur="update('area')"
        >
          <template #label> {{ t("production.labels.area") }} * </template>
        </base-input>

        <base-select
          v-model="payload.area_type"
          :value="payload.area_type"
          :options="areaOptions"
          :required="true"
          :is-empty="false"
          @change="update('area_type')"
        >
          <template #label> {{ t("production.labels.area_type") }} * </template>
        </base-select>

        <base-input
          v-model="payload.price"
          :value="payload.price"
          :mask="maskCurrencyBR"
          @blur="update('price')"
        >
          <template #label>
            {{ t("production.labels.price") }}
          </template>
        </base-input>
      </div>

      <base-textarea
        v-model="payload.description"
        :value="payload.description"
        :placeholder="t('common.placeholders.describe')"
        max-length="650"
        @blur="update('description')"
      >
        <template #label>
          {{ t("common.labels.description") }}
        </template>
      </base-textarea>
    </form>
  </section>
</template>

<style lang="scss" scoped>
.team-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .title {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
}

@media screen and (max-width: 1024px) {
  .team-form {
    .flex-row {
      flex-direction: column;
    }
  }
}
</style>