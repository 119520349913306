import clientAxios from "../client";
import { formatQueryString } from "@/common/utils/common";
import type { QueryType } from "@/common/types/common";
import type { FinancePayloadType } from "@/common/types/finances";

export async function financeList(
    account_id: string,
    type: "all" | "entrance" | "output",
    query?: QueryType
) {
    const queryString = formatQueryString(query);
    return await clientAxios.get(
        `member/${account_id}/finance/list/${type}${queryString}`
    );
}

export async function financeProductionList(
    account_id: string,
    production_id: string,
    query?: QueryType
) {
    const queryString = formatQueryString(query);
    return await clientAxios.get(
        `member/${account_id}/finance/production/${production_id}/list${queryString}`
    );
}

export async function financeGet(
    account_id: string,
    finance_id: string,
) {
    return await clientAxios.get(
        `member/${account_id}/finance/${finance_id}/get`
    );
}

export async function financeReport(
    account_id: string,
    type: string,
    start_date: string,
    end_date: string,
) {
    const queryString = formatQueryString({ type, start_date, end_date });
    return await clientAxios.get(
        `member/${account_id}/finance/report${queryString}`
    );
}

export async function financeAdd(
    account_id: string,
    payload: FinancePayloadType
) {
    return await clientAxios.post(
        `member/${account_id}/finance/add`,
        payload
    );
}

export async function financeProductionAdd(
    account_id: string,
    production_id: string,
    payload: FinancePayloadType
) {
    return await clientAxios.post(
        `member/${account_id}/finance/production/${production_id}/add`,
        payload
    );
}

export async function financePut(
    account_id: string,
    finance_id: string,
    payload: FinancePayloadType
) {
    return await clientAxios.put(
        `member/${account_id}/finance/${finance_id}/edit`,
        payload
    );
}

export async function financeDel(
    account_id: string,
    finance_id: string,
) {
    return await clientAxios.del(
        `member/${account_id}/finance/${finance_id}/del`
    );
}


export async function financeChart(
    account_id: string,
    month: string,
    year: string,
) {
    const queryString = formatQueryString({ month, year });
    return await clientAxios.get(
        `member/${account_id}/finance/chart${queryString}`
    );
}