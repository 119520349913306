<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import { svgIcon } from "@/common/components";

interface MenuListType {
  icon: string;
  label?: string;
  key: string;
  url?: string;
  active?: boolean;
}

const router = useRouter();

const emit = defineEmits(["open"]);

withDefaults(
  defineProps<{
    menuList: MenuListType[];
    active?: string;
    height?: string;
    background?: string;
    iconSize?: string;
  }>(),
  {
    iconSize: "1.75rem",
    height: "4rem",
    background: "#fff",
  }
);

const currentRoute = computed(() => router.currentRoute.value.path);

function select(menu: MenuListType) {
  if (menu.url) {
    router.push(menu.url);
    return;
  }

  emit("open", menu);
}
</script>

<template>
  <section class="footer-menu">
    <template v-for="menu in menuList" :key="menu.key">
      <div
        class="menu"
        :class="{ active: menu.active ? currentRoute.includes(menu.url ?? menu.key) :  false }"
        @click="select(menu)"
      >
        <div class="icon">
          <svg-icon :icon="menu.icon" :size="iconSize" />
        </div>
        <template v-if="menu.label">
          <label>{{ menu.label }}</label>
        </template>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.footer-menu {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  background: v-bind("background");
  height: calc(v-bind("height") - 2px);
  box-shadow: -3px -2px 5px var(--shadow);
  border-top: 1px solid var(--text-off);

  .menu {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    color: var(--text-off);

    &.active {
      color: var(--primary);
    }

    .icon {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    label {
      font-size: 0.75rem;
    }
  }
}
</style>