export const plan = {
    labels: {
        affiliate_accounts: "Affiliate Accounts",
        plan: "Plan",
        plans: "Plans",
        invoice: "Invoice",
        invoices: "Invoices",
        my_account: "My Account",
        my_plans: "My Plans",
        my_signature: "My Signature",
        my_invoices: "My Invoices",
    },
    texts: {
        account_empty: "You don't have an account yet.",
        affiliate_accounts: "You don't have access to other accounts yet.",
        invoice_empty: "You have no invoices yet.",
        signature_empty: "You don't have a signature yet.",
    },
};
