<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";

import { maskDateBR, maskCurrencyBR } from "@/common/utils/mask";

import { ProductionStatusEnum } from "@/common/types/production";
import type { ProductionType } from "@/common/types/production";

import { svgIcon } from "@/common/components";

const { t, tm } = useI18n();

const props = defineProps<{
  data: ProductionType;
}>();

const area = computed(() =>
  props.data ? Number.parseFloat(String(props.data.area)).toFixed(2) : null
);

const statusOptions = computed(() => tm("production.enum.status") as Record<string, string>);
const areaOptions = computed(() => tm("production.enum.area") as Record<string, string>);
</script>

<template>
  <template v-if="data">
    <article class="card-production">
      <section class="avatar">
        <div class="image">
          <template v-if="data.avatar">
            <img :src="data.avatar" alt="avatar" />
          </template>
          <template v-else>
            <svg-icon icon="no-image" size="2.5rem" />
          </template>
        </div>
      </section>
      <section class="infos">
        <div class="flex flex-col gap-3">
          <div class="code">
            {{ data.code }}
          </div>
          <div class="name">
            {{ data.name }}
          </div>
        </div>

        <div class="lines">
          <div class="line">
            <span> {{ t("common.labels.status") }}: </span>
            <strong>
              {{ statusOptions[data.status] }}
            </strong>
          </div>
          <div class="line">
            <span> {{ t("production.labels.area") }}: </span>
            <strong>
              {{ maskCurrencyBR(area) }} {{ areaOptions[data.area_type] }}
            </strong>
          </div>
        </div>
        <div class="lines">
          <div class="line">
            <span> {{ t("production.labels.planting") }}: </span>
            <template v-if="data.start_date">
              <strong>
                {{ moment(data.start_date, "YYYY-MM-DD").format("DD/MM/YYYY") }}
              </strong>
            </template>
          </div>
          <div class="line">
            <span>
              {{ t("production.labels.harvest") }}
            </span>
            <template v-if="data.end_date">
              <strong>
                {{ moment(data.end_date, "YYYY-MM-DD").format("DD/MM/YYYY") }}
              </strong>
            </template>
          </div>
        </div>
      </section>
    </article>
  </template>
  <template v-else>
    <div class="card-production">
      <section class="avatar">
        <div class="image" />
      </section>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.card-production {
  cursor: pointer;
  display: grid;
  grid-template-columns: 5rem 1fr;
  gap: 1.5rem;
  background: #ffffff;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid var(--primary);
  }

  .avatar {
    display: flex;
    width: 5rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    background: var(--background);
    border-radius: 0.5rem;
    color: var(--text-off);
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }

  .infos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    .code {
      width: fit-content;
      padding-block: 0.2rem;
      padding-inline: 0.75rem;
      border-radius: 0.5rem;
      background: var(--background);
      font-size: 1rem;
      font-weight: bold;
    }

    .name {
      flex: 1;
      padding-inline-start: 0.75rem;
    }

    .lines {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .line {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 1rem;
      }
    }
  }
}
</style>