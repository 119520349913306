
<script setup lang="ts">
import { ref, onMounted } from "vue";
import Chart from 'primevue/chart';

interface ChartLineType {
  data: number[];
  backgroundColor?: string[];
  borderColor?: string[];
  tension?: number;
}

const props = defineProps<{
  labels: string[];
  data: ChartLineType[];
}>();

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  return {
    labels: props.labels,
    datasets: props.data,
  };
};

const setChartOptions = () => {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: "#000000",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#181818",
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          color: "#181818",
        },
        grid: {
          color: "transparent",
          drawBorder: false,
        },
      },
    },
  };
};

onMounted(() => {
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
});
</script>

<template>
  <Chart class="chart-bar" type="pie" :data="chartData" :options="chartOptions" />
</template>

<style lang="scss" scoped>
.chart-bar {
    min-height: 280px;
}
</style>
