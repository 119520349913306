import { UnitEnum, StockStatus, StockTypes } from "@/common/types/stock";

export const stock = {
    labels: {
        stock: "Estoque",
        stock_add: "Adicionar estoque",
    },
    modals: {
        titles: {
            remove_stock: "Remover item do estoque",
            remove_movement: "Remover movimentação",
        },
        messages: {
            remove_stock: "Você deseja remover este item do <strong>ESTOQUE</strong>?<br/>Esta ação não poderá ser desfeita!",
            remove_movement: "Você deseja remover esta <strong>MOVIMENTAÇÃO</strong>?<br/>Esta ação não poderá ser desfeita!",
        },
    },
    enum: {
        status: {
            [StockStatus.IN_STOCK]: "Em estoque",
            [StockStatus.OUT_STOCK]: "Sem estoque",
            [StockStatus.ON_HOLD]: "Em espera",
            [StockStatus.RESERVED]: "Reservado",
            [StockStatus.DEFECT]: "Defeito",
            [StockStatus.BLOCKED]: "Bloqueado",
            [StockStatus.REMOVED]: "Removido",
        },
        types: {
            [StockTypes.CULTURE]: "Cultura",
            [StockTypes.PRODUCT]: "Produto",
            [StockTypes.MACHINE]: "Máquina",
            [StockTypes.OTHER]: "Outro",
        },
        units_name: {
            [UnitEnum.BALE]: "Fardo",
            [UnitEnum.BAG]: "Saco",
            [UnitEnum.BOX]: "Caixa",
            [UnitEnum.CENTIMETER]: "Centímetro",
            [UnitEnum.CUBIC_METER]: "Metro cúbico",
            [UnitEnum.GRAM]: "Grama",
            [UnitEnum.KILOGRAM]: "Quilograma",
            [UnitEnum.LITER]: "Litro",
            [UnitEnum.LOT]: "Lote",
            [UnitEnum.MILLILITER]: "Mililitro",
            [UnitEnum.METER]: "Metro",
            [UnitEnum.MILLIMETER]: "Milímetro",
            [UnitEnum.PACKAGE]: "Pacote",
            [UnitEnum.PALLET]: "Palete",
            [UnitEnum.PAIR]: "Par",
            [UnitEnum.ROLL]: "Rolo",
            [UnitEnum.TON]: "Tonelada",
            [UnitEnum.UNIT]: "Unidade",
        },
        units_short: {
            [UnitEnum.BALE]: "fd",
            [UnitEnum.BAG]: "sc",
            [UnitEnum.BOX]: "cx",
            [UnitEnum.CENTIMETER]: "cm",
            [UnitEnum.CUBIC_METER]: "m³",
            [UnitEnum.GRAM]: "g",
            [UnitEnum.KILOGRAM]: "kg",
            [UnitEnum.LITER]: "L",
            [UnitEnum.LOT]: "lote",
            [UnitEnum.MILLILITER]: "ml",
            [UnitEnum.METER]: "m",
            [UnitEnum.MILLIMETER]: "mm",
            [UnitEnum.PACKAGE]: "pct",
            [UnitEnum.PALLET]: "pal",
            [UnitEnum.PAIR]: "par",
            [UnitEnum.ROLL]: "rol",
            [UnitEnum.TON]: "t",
            [UnitEnum.UNIT]: "un",
        },
    },
    texts: {},
};
