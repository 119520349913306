<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { MD5 } from "crypto-js";

import { toast } from "@/common/utils/toast";
import { maskDateBR } from "@/common/utils/mask";

import { baseInput, svgIcon } from "@/common/components";

import { userRegister } from "@/common/api/rest/user";
import type { UserRegisterType } from "@/common/types/user";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const hash = computed(() => {
  try {
    const decode = window.atob(String(route.query.hash));
    return JSON.parse(decode);
  } catch (_) {
    return null;
  }
});

const payload = ref<UserRegisterType>({
  name: "",
  birthdate: "",
  login: "",
  email: "",
  password: "",
});

const confirmPassword = ref("");
const step = ref(1);

const error = ref<{ [key: string]: string }>({});

const passwordShow = ref(false);
const confirmPasswordShow = ref(false);

function isValid() {
  const { name, birthdate, email, password } = payload.value;

  error.value = {};

  if (step.value === 1) {
    if (name.trim().length === 0) error.value.name = t("error.required");

    if (birthdate.trim().length === 0)
      error.value.birthdate = t("error.required");
  }

  if (step.value === 2) {
    if (email.trim().length === 0) error.value.email = t("error.required");

    if (!email.includes("@")) error.value.email = t("error.invalid");
  }

  if (step.value === 3) {
    if (password.trim().length === 0)
      error.value.password = t("error.required");

    if (confirmPassword.value.trim().length === 0)
      error.value.confirm = t("error.required");

    if (password !== confirmPassword.value)
      error.value.confirm = t("error.not_equal");
  }

  return Object.keys(error.value).length === 0;
}

function goTo(url: string) {
  router.push(url);
}

function beforeStep() {
  router.push({
    path: "/auth/register",
    query: {
      hash: window.btoa(
        JSON.stringify({
          payload: payload.value,
          step: step.value - 1,
        })
      ),
    },
  });
}

function nextStep() {
  if (!isValid()) return;

  router.push({
    path: "/auth/register",
    query: {
      hash: window.btoa(
        JSON.stringify({
          payload: payload.value,
          step: step.value + 1,
        })
      ),
    },
  });
}

function submit() {
  if (!isValid()) return;

  userRegister({
    ...payload.value,
    password: MD5(payload.value.password).toString(),
  })
    .then((response) => {
      const { success, data } = response;
      if (success) {
        toast({ message: data.message, type: "success" });
        router.push("/auth/login");
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

watchEffect(() => {
  if (hash.value) {
    payload.value = hash.value.payload;
    step.value = hash.value.step;
  }
});
</script>

<template>
  <main class="screen">
    <section class="box-login shadow">
      <div class="left">
        <img :src="require('@/assets/images/logo.png')" />
      </div>

      <div class="right">
        <template v-if="step === 1">
          <h2>
            {{ t("common.texts.register_account").toUpperCase() }}
          </h2>
          <form @submit.prevent="nextStep()">
            <base-input
              v-model="payload.name"
              :value="payload.name"
              :placeholder="t('common.placeholders.name')"
              background="#f1f1f1"
              maxLength="50"
            >
              <template #label>
                {{ t("common.labels.name") }}
              </template>
              <template v-if="error.name" #error>
                {{ error.name }}
              </template>
            </base-input>

            <base-input
              v-model="payload.birthdate"
              :value="payload.birthdate"
              :mask="maskDateBR"
              placeholder="dd/mm/yyyy"
              background="#f1f1f1"
              minLength="10"
              maxLength="10"
            >
              <template #label>
                {{ t("common.labels.birthdate") }}
              </template>
              <template v-if="error.birthdate" #error>
                {{ error.birthdate }}
              </template>
            </base-input>

            <div class="actions">
              <button class="send btn btn-primary">
                {{ t("common.labels.next")?.toUpperCase() }}
              </button>

              <button
                type="button"
                class="back btn btn-sm btn-default"
                @click="goTo('/auth/login')"
              >
                {{ t("common.labels.back")?.toUpperCase() }}
              </button>
            </div>
          </form>
        </template>

        <template v-if="step === 2">
          <h2>
            {{ t("common.texts.register_account").toUpperCase() }}
          </h2>
          <form @submit.prevent="nextStep()">
            <base-input
              v-model="payload.login"
              :value="payload.login"
              :placeholder="t('common.placeholders.login')"
              background="#f1f1f1"
              minLength="5"
              maxLength="50"
            >
              <template #label>
                {{ t("common.labels.login") }}
              </template>
              <template v-if="error.login" #error>
                {{ error.login }}
              </template>
            </base-input>

            <base-input
              v-model="payload.email"
              type="email"
              :value="payload.email"
              :placeholder="t('common.placeholders.email')"
              background="#f1f1f1"
              minLength="8"
              maxLength="50"
            >
              <template #label>
                {{ t("common.labels.email") }}
              </template>
              <template v-if="error.email" #error>
                {{ error.email }}
              </template>
            </base-input>

            <div class="actions">
              <button class="send btn btn-primary">
                {{ t("common.labels.next")?.toUpperCase() }}
              </button>

              <button
                type="button"
                class="back btn btn-sm btn-default"
                @click="beforeStep()"
              >
                {{ t("common.labels.back")?.toUpperCase() }}
              </button>
            </div>
          </form>
        </template>

        <template v-if="step === 3">
          <h2>
            {{ t("common.texts.register_account").toUpperCase() }}
          </h2>
          <form @submit.prevent="submit()">
            <base-input
              v-model="payload.password"
              :placeholder="t('common.placeholders.password')"
              :type="passwordShow ? 'text' : 'password'"
              background="#f1f1f1"
              maxLength="50"
            >
              <template #label>
                {{ t("common.labels.password") }}
              </template>
              <template #sufix>
                <div
                  v-if="passwordShow"
                  class="pointer"
                  @click="passwordShow = false"
                >
                  <svg-icon icon="eye" size="1.5rem" />
                </div>
                <div v-else class="pointer" @click="passwordShow = true">
                  <svg-icon icon="eye-closed" size="1.5rem" />
                </div>
              </template>
              <template v-if="error.password" #error>
                {{ error.password }}
              </template>
            </base-input>

            <base-input
              v-model="confirmPassword"
              :placeholder="t('common.placeholders.password')"
              :type="confirmPasswordShow ? 'text' : 'password'"
              background="#f1f1f1"
              maxLength="50"
            >
              <template #label>
                {{ t("common.labels.confirm_password") }}
              </template>
              <template #sufix>
                <div
                  v-if="confirmPasswordShow"
                  class="pointer"
                  @click="confirmPasswordShow = false"
                >
                  <svg-icon icon="eye" size="1.5rem" />
                </div>
                <div v-else class="pointer" @click="confirmPasswordShow = true">
                  <svg-icon icon="eye-closed" size="1.5rem" />
                </div>
              </template>
              <template v-if="error.confirm" #error>
                {{ error.confirm }}
              </template>
            </base-input>

            <div class="actions">
              <button class="send btn btn-primary">
                {{ t("common.labels.register")?.toUpperCase() }}
              </button>

              <button
                type="button"
                class="back btn btn-sm btn-default"
                @click="beforeStep()"
              >
                {{ t("common.labels.back")?.toUpperCase() }}
              </button>
            </div>
          </form>
        </template>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>
.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  background: var(--black-background);

  .box-login {
    display: flex;
    flex-direction: row;
    width: 650px;
    height: 500px;
    background: var(--white);
    border-radius: 0.75rem;

    .left {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 150px;
      }
    }

    .right {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding-inline: 8%;

      form {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.875rem;
      }

      .actions {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-block-start: 1rem;
        gap: 1.5rem;

        .send {
          width: 100%;
        }

        .back {
          width: 6rem;
          height: 2rem;
          font-size: 0.875rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .screen {
    align-items: center;
    justify-content: flex-start;
    padding-block: 5%;

    .box-login {
      flex-direction: column;
      width: 90%;
      height: auto;
      margin-block: auto;
      padding-block: 1.5rem;

      .left {
        width: 100%;
        padding-block: 1.5rem;
      }

      .right {
        width: 84%;
        margin-inline: auto;
      }
    }
  }
}
</style>