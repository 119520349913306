<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { svgIcon } from "@/common/components";

const props = withDefaults(
  defineProps<{
    open: boolean;
    paddingBlock?: string;
    paddingInline?: string;
    headerPaddingBlock?: string;
    headerPaddingInline?: string;
  }>(),
  {
    paddingBlock: "1rem",
    paddingInline: "1.25rem",
    headerPaddingBlock: "0.75rem",
    headerPaddingInline: "1rem",
  }
);

const emit = defineEmits(["open"]);

const opened = ref(false);

function update() {
  opened.value = !opened.value;
  emit("open", opened.value);
}

watchEffect(() => {
  opened.value = props.open;
});
</script>

<template>
  <div class="base-accordion">
    <div class="header" @click="update()">
      <div>
        <div v-if="$slots.icon" class="icon">
          <slot name="icon"></slot>
        </div>
        <div class="title">
          <slot name="title"></slot>
        </div>
      </div>
      <div>
        <svg-icon :icon="opened ? 'arrow-up' : 'arrow-down'" size="15px" />
      </div>
    </div>
    <div
      v-if="opened"
      class="content"
      :style="{
        paddingBlock: paddingBlock,
        paddingInline: paddingInline,
      }"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-accordion {
  display: flex;
  flex-direction: column;

  .header {
    cursor: pointer;
    user-select: none;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-block: v-bind("headerPaddingBlock");
    padding-inline: v-bind("headerPaddingInline");
    border: 1px solid #a8a8a8;
    background: #fff;

    div:nth-child(1) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 0.75rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background: #fff;
  }
}
</style>