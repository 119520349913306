<script setup lang="ts">
import store from "@/store";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import {
  mobileHeader,
  footerMenu,
  mobileMenu,
  fullLoading,
} from "@/common/components";

interface MenuListType {
  icon: string;
  label?: string;
  url?: string;
  key: string;
}

const { t } = useI18n();

defineProps<{
  accountId: string;
  menuList: MenuListType[];
}>();

const headerHeight = "4rem";
const footerHeight = "4.5rem";
</script>

<template>
  <section class="screen">
    <section class="pages">
      <mobile-header :height="headerHeight" background="#333333" />
      <main class="main">
        <slot name="main"></slot>
      </main>
      <footer-menu
        :menu-list="menuList"
        :height="footerHeight"
        background="#333333"
      />
    </section>

    <template v-if="store.getters.loading">
      <full-loading />
    </template>
  </section>
</template>

<style lang="scss" scoped>
$header-height: v-bind("headerHeight");
$footer-height: v-bind("footerHeight");

.screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;

  .pages {
    display: flex;
    flex-direction: column;

    main {
      display: flex;
      flex-direction: column;
      padding-block: 1rem;
      padding-inline: 1rem;
      height: calc(100dvh - $header-height - $footer-height - 2rem);
      overflow-y: auto;
    }
  }
}
</style>