<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import {
  maskDateBR,
  maskCurrencyBR,
  currencyBRtoFloat,
} from "@/common/utils/mask";

import {
  baseAvatar,
  baseInput,
  baseDate,
  baseSelect,
  baseTextarea,
} from "@/common/components";

import type { StockType, StockPayloadType } from "@/common/types/stock";
import moment from "moment";

const { t, tm } = useI18n();

const props = defineProps<{
  data: StockType | null;
}>();

const emit = defineEmits(["update-data", "validate"]);

const defaultPayload = {
  code: "",
  status: "",
  name: "",
  price: "",
  type: "",
  unit_name: "",
  unit_short: "",
  validity: "",
  description: "",
};

const payload = ref<StockPayloadType>({ ...defaultPayload });

const statusOptions = computed(() =>
  Object.entries(tm("stock.enum.status") as Record<string, string>).map(
    ([value, option]) => ({
      option,
      value,
    })
  )
);

const typesOptions = computed(() =>
  Object.entries(tm("stock.enum.types") as Record<string, string>).map(
    ([value, option]) => ({
      option,
      value,
    })
  )
);

const unitOptions = computed(() =>
  Object.entries(tm("stock.enum.units_name") as Record<string, string>).map(
    ([value, option]) => ({
      option,
      value,
    })
  )
);

function validate() {
  const { code, status, name, price, unit_name, type } = payload.value;

  const valid = code && status && name && price && unit_name && type;

  emit("validate", valid);
}

function update() {
  emit("update-data", {
    ...payload.value,
    price: currencyBRtoFloat(payload.value.price.toString()),
  });
  validate();
}

onMounted(() => {
  if (!props.data) return;

  const {
    code,
    status,
    name,
    price,
    unit_name,
    validity,
    type,
    description,
  } = props.data;

  payload.value = {
    code,
    status,
    name,
    price: maskCurrencyBR(Number.parseFloat(String(price || "0")).toFixed(2)),
    unit_name,
    validity,
    type,
    description,
  };

  update();
});
</script>

<template>
  <section class="form-content">
    <form class="flex flex-col gap-4">
      <div class="flex flex-row gap-4">
        <div class="flex-1">
          <base-select
            v-model="payload.type"
            :value="payload.type"
            :options="typesOptions"
            required
            @change="update()"
          >
            <template #label> {{ t("common.labels.type") }} * </template>
          </base-select>
        </div>

        <div class="flex-1">
          <base-input
            v-model="payload.code"
            :value="payload.code"
            :placeholder="t('common.placeholders.code')"
            required
            @update:model-value="payload.code = payload.code.toUpperCase()"
            @blur="update()"
          >
            <template #label> {{ t("common.labels.code") }} *</template>
          </base-input>
        </div>
      </div>

      <base-input
        v-model="payload.name"
        :value="payload.name"
        :placeholder="t('common.placeholders.name')"
        required
        @blur="update()"
      >
        <template #label> {{ t("common.labels.name") }} *</template>
      </base-input>

      <div class="flex flex-row gap-4">
        <div class="flex-1">
          <base-select
            v-model="payload.status"
            :value="payload.status"
            :options="statusOptions"
            required
            @change="update()"
          >
            <template #label> {{ t("common.labels.status") }} * </template>
          </base-select>
        </div>

        <div class="flex-1">
          <base-date
            v-model="payload.validity"
            :value="payload.validity"
            value-mask="YYYY-MM-DD"
            placeholder="dd/mm/yyyy"
            @update:modelValue="update()"
          >
            <template #label> {{ t("common.labels.validity") }}</template>
          </base-date>
        </div>
      </div>

      <div class="flex flex-row gap-4">
        <div class="flex-1">
          <base-select
            v-model="payload.unit_name"
            :value="payload.unit_name"
            :options="unitOptions"
            required
            @change="update()"
          >
            <template #label> {{ t("common.labels.unit") }} * </template>
          </base-select>
        </div>

        <div class="flex-1">
          <base-input
            v-model="payload.price"
            :value="payload.price"
            :mask="maskCurrencyBR"
            placeholder="0,0"
            required
            @blur="update()"
          >
            <template #label> {{ t("common.labels.value") }} *</template>
          </base-input>
        </div>
      </div>

      <base-textarea
        v-model="payload.description"
        :value="payload.description"
        :placeholder="t('common.placeholders.describe')"
        @blur="update()"
      >
        <template #label>
          {{ t("common.labels.description") }}
        </template>
      </base-textarea>
    </form>
  </section>
</template>

<style lang="scss" scoped>
.form-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

@media screen and (max-width: 1024px) {
  .form-content {
    padding-inline: 0.5rem;
    .flex-row {
      flex-direction: column;
    }
  }
}
</style>