<script setup lang="ts">
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { toast } from "@/common/utils/toast";

import { PanelTheme } from "@/common/themes";

import { svgIcon, baseSelect, baseTable } from "@/common/components";
import { CardWeather } from "@/components";

const { t } = useI18n();
const route = useRoute();

const monthOptions = [];

const accountId = computed(() => route.params.id as string);
const memberId = computed(() => store.getters.user.member_id as string);

const tableHeader = computed(() => [
  { key: "code", label: "Código" },
  { key: "company", label: "Semente" },
  { key: "date", label: "Status" },
  { key: "date", label: "Plantio" },
  { key: "date", label: "Colheita" },
]);

const tableData = ref([]);

function fecthData() {
  console.log("fecthData");
}

onMounted(() => {
  fecthData();
});
</script>

<template>
  <panel-theme>
    <section class="home-page">
      <div class="group">
        <div class="box welcome">
          <span> Bem vindo </span>
          <template v-if="store.getters.profile?.social_name">
            <strong>
              {{ store.getters.profile?.social_name }}
            </strong>
          </template>
          <template v-else>
            <strong>
              {{ store.getters.profile?.first_name }}
              {{ store.getters.profile?.last_name }}
            </strong>
          </template>
        </div>
      </div>

      <div class="box">
        <card-weather city="Santo André, SP" />
      </div>
    </section>
  </panel-theme>
</template>

<style lang="scss" scoped>
.home-page {
  display: flex;
  flex-direction: column;
  gap: 1.54rem;
  padding-block-end: 2rem;

  .box {
    width: 100%;
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.375rem;
  }

  .group {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1.54rem;
  }

  .welcome {
    font-size: 1.25rem;
  }

  .evaluations {
    display: flex;
    width: 37rem;
    flex-direction: column;
    gap: 1rem;

    .header {
      display: grid;
      grid-template-columns: auto 9.5rem;
      gap: 1rem;
      align-items: center;

      .title {
        font-size: 1.25rem;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .time {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.75rem;
      font-weight: bold;
    }
  }

  .my-evaluations {
    .title {
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-page {
    .group {
      flex-direction: column;
    }

    .evaluations {
      width: calc(100% - (1.375rem * 2));

      .header {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
</style>