<script setup lang="ts">
import { ref, watch, onBeforeUnmount, nextTick, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import { svgIcon } from "@/common/components";

import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

const { t } = useI18n();

const emit = defineEmits(["change", "del"]);

const props = withDefaults(
  defineProps<{
    url?: string | null;
    size?: string;
    isEdit?: boolean;
    background?: string;
    position?: "center" | "left" | "right";
  }>(),
  {
    size: "9rem",
    isEdit: false,
    position: "right",
    background: "#eeeeee",
  }
);

const fileInput = ref();

const imageUrl = ref<string | null>(null);
const imageElement = ref<HTMLImageElement | null>(null);

const cropper = ref<Cropper | null>(null);

function fileSelect(event: any) {
  const file = (event.target as HTMLInputElement).files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      imageUrl.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  }
}

function cropImage() {
  if (cropper.value) {
    cropper.value.getCroppedCanvas().toBlob((blob) => {
      if (blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          emit("change", reader.result);
          imageUrl.value = null;
          fileInput.value.value = "";
        };
        reader.readAsDataURL(blob);
      }
    });
  }
}

function cropImageCancel() {
  imageUrl.value = null;
  fileInput.value.value = "";
}

function fileDel() {
  emit("del");
}

watch(
  () => imageUrl.value,
  async () => {
    await nextTick(async () => {
      if (imageUrl.value) {
        if (cropper.value) cropper.value.destroy();

        await nextTick(() => {
          if (imageElement.value) {
            cropper.value = new Cropper(imageElement.value, {
              aspectRatio: 1,
              viewMode: 1,
            });
          }
        });
      }
    });
  }
);

onBeforeUnmount(() => {
  if (cropper.value) {
    cropper.value.destroy();
  }
});
</script>

<template>
  <div class="base-avatar" :class="[props.position]">
    <template v-if="props.url">
      <div class="avatar">
        <img :src="props.url" />
      </div>
    </template>

    <template v-else>
      <div class="avatar">
        <svg-icon icon="no-image" />
      </div>
    </template>

    <template v-if="props.isEdit">
      <div class="actions">
        <template v-if="props.url">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="fileInput.click()"
          >
            <svg-icon icon="refresh" size="1.5rem" />
          </button>

          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="fileDel()"
          >
            <svg-icon icon="trash" size="1.5rem" />
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="fileInput.click()"
          >
            <svg-icon icon="upload" size="1.5rem" />
            {{ t("common.labels.upload") }}
          </button>
        </template>
      </div>

      <template v-if="imageUrl">
        <div class="crop-image">
          <div class="content">
            <div class="crop">
              <img ref="imageElement" :src="imageUrl" alt="Source Image" />
            </div>
            <div class="actions">
              <button
                type="button"
                class="btn btn-sm btn-default"
                @click="cropImage"
              >
                {{ t("common.labels.save") }}
              </button>
              <button
                type="button"
                class="btn btn-sm btn-default"
                @click="cropImageCancel"
              >
                {{ t("common.labels.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </template>

      <input
        ref="fileInput"
        type="file"
        @change="fileSelect"
        accept="image/*"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
$size: v-bind("props.size");

.base-avatar {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 50%;
    background: v-bind("background");
    color: var(--text-medium);
    overflow: hidden;

    :deep(.svgicon) {
      width: calc($size * 0.75);
      height: calc($size * 0.75);
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &.center {
    flex-direction: column;

    .actions {
      flex-direction: row;
    }
  }

  &.right {
    flex-direction: row;

    .actions {
      flex-direction: column;
    }
  }

  &.left {
    flex-direction: row-reverse;

    .actions {
      flex-direction: row;
    }
  }

  .crop-image {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    width: 100vw;
    height: 100dvh;
    background: #00000083;

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: calc(70% - (0.75rem * 2));
      height: 30rem;
      margin-block: auto;
      margin-inline: auto;
      background: #fff;
      border-radius: 0.5rem;
      padding: 0.75rem;

      .crop {
        width: 100%;
        height: 28rem;
        overflow: hidden;
      }

      .actions {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }
  }

  input {
    display: none;
  }

  img {
    width: 100%;
  }
}
</style>