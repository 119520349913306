import { language as portugueseLanguage } from "./pt-br";
import { language as englishLanguage } from "./en-us";

const messages = {
    "pt-BR": {
        empty: "",
        array_empty: [],
        ...portugueseLanguage.translations,
    },
    "en-US": {
        empty: "",
        array_empty: [],
        ...englishLanguage.translations,
    },
};

export { messages };
