export const date = {
    labels: {
        month: "Mês",
        months: "Meses",
        year: "Ano",
        years: "Anos",
    },
    months: {
        "January": "Janeiro",
        "February": "Fevereiro",
        "March": "Março",
        "April": "Abril",
        "May": "Maio",
        "June": "Junho",
        "July": "Julho",
        "August": "Agosto",
        "September": "Setembro",
        "October": "Outubro",
        "November": "Novembro",
        "December": "Dezembro",
    },
};
