export const user = {
    types: {
        admin: "Administrator",
        manager: "Manager",
        technical: "Technical",
    },
    labels: {
        uninformed: "Uninformed",
    },
};
