<script setup lang="ts">
import InlineSvg from "vue-inline-svg";

const props = withDefaults(
  defineProps<{
    icon: string;
    size?: string;
    path?: string;
    color?: string;
  }>(),
  {
    path: "icons",
    size: "30px",
    color: "currentColor",
  }
);
</script>

<template>
  <div class="svgicon">
    <template v-if="props.icon">
      <inline-svg :src="require(`@/assets/${props.path}/${props.icon}.svg`)" />
    </template>
    <template v-else>
      <inline-svg :src="require('@/assets/icons/no-image.svg')" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.svgicon {
  display: flex;
  flex-direction: column;
  width: v-bind("size");
  height: v-bind("size");
  color: v-bind("color");
  align-items: center;
  justify-content: center;
}
</style>
