<script setup lang="ts">
import store from "@/store";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { MobileDefaultTheme, DesktopDefaultTheme } from ".";
import { baseHeader, sideMenu, fullLoading } from "@/common/components";

const { t } = useI18n();
const route = useRoute();

const isMobile = ref(false);

const menuList = computed(() => [
  {
    icon: "change",
    label: t("common.labels.change_account"),
    url: "/accounts",
    active: true,
  },
  {
    icon: "plans",
    label: t("common.labels.plans"),
    url: "/plans",
    active: true,
  },
]);
</script>

<template>
  <template v-if="$store.getters.mobile">
    <mobile-default-theme :menu-list="menuList">
      <template #main>
        <slot></slot>
      </template>
    </mobile-default-theme>
  </template>
  <template v-else>
    <desktop-default-theme :menu-list="menuList">
      <template #main>
        <slot></slot>
      </template>
    </desktop-default-theme>
  </template>
</template>

<style lang="scss" scoped>
.screen {
  display: grid;
  width: 100%;
  height: 100dvh;
  grid-template-columns: auto 1fr;

  .pages {
    display: flex;
    flex-direction: column;

    main {
      display: flex;
      flex-direction: column;
      padding-block-start: 1.57rem;
      padding-inline: 1.57rem;
      height: calc(100dvh - 80px - 1.57rem);
      overflow-y: auto;
    }
  }
}
</style>