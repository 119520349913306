<script setup lang="ts">
import store from "@/store";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { toast } from "@/common/utils/toast";
import { DefaultTheme } from "@/common/themes";

import { userAccount } from "@/common/api/rest/user";
import { UserAccountType, UserAccountAffiliateType } from "@/common/types/user";

const { t } = useI18n();
const router = useRouter();

const account = ref<UserAccountType | null>(null);
const affiliate = ref<UserAccountAffiliateType[] | null>(null);

function goToAccount(account: UserAccountType) {
  store.commit("SET_TYPE", account.type);
  router.push(`/${account.account_id}/home`);
}

function goToAffiliate(account: UserAccountAffiliateType) {
  store.commit("SET_TYPE", account.type);
  router.push(`/${account.account_id}/home`);
}

function fecthAccount() {
  userAccount(store.getters.user?.member_id)
    .then((response) => {
      const { success, data } = response;
      if (success) {
        account.value = data.owner;
        affiliate.value = data.affiliate;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

onMounted(() => {
  fecthAccount();
  store.commit("SET_TYPE", null);
});
</script>

<template>
  <default-theme>
    <section class="accounts-page">
      <div class="box">
        <div class="title">
          {{ t("common.labels.accounts") }}
        </div>

        <div class="content">
          <template v-if="account">
            <div class="item" @click="goToAccount(account)">
              <div class="name">
                <template v-if="account.name">
                  {{ t("common.labels.my_account") }} - {{ account.name }}
                </template>
                <teplate v-else>
                  {{ t("common.labels.my_account") }}
                </teplate>
              </div>
              <div class="description">
                {{ account.description }}
              </div>
            </div>
          </template>

          <template v-if="affiliate">
            <template v-for="(item, index) in affiliate" :key="index">
              <div class="item" @click="goToAffiliate(item)">
                <div class="name">
                  {{ item.name || t("user.labels.uninformed") }}
                </div>
                <div class="description">
                  {{ item.description }}
                </div>
              </div>
            </template>
          </template>

          <template v-if="!account && !affiliate">
            <div class="empty">
              {{ t("plan.texts.account_empty") }}
            </div>
          </template>
        </div>
      </div>
    </section>
  </default-theme>
</template>

<style lang="scss" scoped>
.accounts-page {
  display: flex;
  flex-direction: column;
  gap: 1.54rem;

  .title {
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.375rem;
    gap: 1rem;

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .item {
        cursor: pointer;
        user-select: none;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-block: 0.75rem;
        padding-inline: 1rem;
        border: 1px solid var(--border);
        border-radius: 0.5rem;

        .name {
          font-size: 1.1rem;
          font-weight: bold;
          text-transform: uppercase;
        }

        &:hover {
          background: var(--primary);
          color: var(--text);
        }
      }
    }
  }

  .group {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1.54rem;
  }
}
</style>