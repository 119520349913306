import { get } from "lodash-es";
import domtoimage from 'dom-to-image';

export const getImage = (data: {
    image?: string | null;
    is_local?: boolean;
}) => {
    const image = get(data, "image", null);
    const isLocal = get(data, "is_local", true);

    if (!image) {
        return null;
    }
    try {
        const host = process.env.VUE_APP_HOST;
        const newImage = require(`@/assets/${image}`);

        if (!isLocal)
            return image;

        if (newImage.includes("base64"))
            return newImage;

        return `${host}${newImage}`;
    } catch (error) {
        console.log("error", error);
        return null;
    }
};

export async function divToImage(name = "image.jpg", div: any) {
    const dataUrl = await domtoimage.toJpeg(div);

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = name;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}

export function redimensionarImagem(file: File, maxWidth: number, callback: any) {
    const img = new Image();

    img.onload = function () {
        const width = img.width;
        let height = img.height;

        const ratio = maxWidth / width;
        height = height * ratio;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = maxWidth;
        canvas.height = height;

        ctx?.drawImage(img, 0, 0, maxWidth, height);

        canvas.toBlob(function (blob) {
            callback(blob);
        }, file.type);
    };

    img.src = URL.createObjectURL(file);
}

export async function convertFileToBase64(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


function getDrawnRegion(ctx: any, canvas: any) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;
    const len = pixels.length;
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < len; i += 4) {
        const alpha = pixels[i + 3];
        if (alpha > 0) {
            const x = (i / 4) % canvas.width;
            const y = Math.floor(i / (4 * canvas.width));
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        }
    }

    const padding = 5;
    minX = Math.max(0, minX - padding);
    minY = Math.max(0, minY - padding);
    maxX = Math.min(canvas.width - 1, maxX + padding);
    maxY = Math.min(canvas.height - 1, maxY + padding);

    return { minX, minY, maxX, maxY };
}

export function cropSignature(ctx: any, canvas: any) {
    const { minX, minY, maxX, maxY } = getDrawnRegion(ctx, canvas);
    const width = maxX - minX + 1;
    const height = maxY - minY + 1;

    const croppedCanvas = document.createElement("canvas");
    const croppedCtx = croppedCanvas.getContext("2d");
    croppedCanvas.width = width;
    croppedCanvas.height = height;
    croppedCtx?.drawImage(canvas, minX, minY, width, height, 0, 0, width, height);

    return croppedCanvas.toDataURL("image/png");
}