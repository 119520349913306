<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import moment from "moment";

import {
  currencyBRtoFloat,
  currencyFloatToBR,
  maskDateBR,
  maskDateUS,
} from "@/common/utils/mask";
import { toast } from "@/common/utils/toast";

import { PanelTheme } from "@/common/themes";

import {
  baseConfirm,
  baseInput,
  baseDate,
  baseSelect,
  baseModal,
  baseTable,
  tabMenu,
} from "@/common/components";

import { financeForm, ChartBars } from "@/components";

import {
  financeList,
  financeReport,
  financeAdd,
  financePut,
  financeDel,
  financeChart,
} from "@/common/api/rest/finance";

import type {
  FinanceType,
  FinancePayloadType,
  FinanceTypes,
} from "@/common/types/finances";
import store from "@/store";

const { t, tm } = useI18n();

const route = useRoute();

const accountId = computed(() => route.params.id as string);

const tabList = computed(() => [
  {
    key: "all",
    label: t("common.labels.finances").toUpperCase(),
  },
  {
    key: "entrance",
    label: t("common.labels.entrance").toUpperCase(),
  },
  {
    key: "output",
    label: t("common.labels.output").toUpperCase(),
  },
  {
    key: "report",
    label: t("common.labels.report").toUpperCase(),
  },
  {
    key: "charts",
    label: t("finance.labels.charts").toUpperCase(),
  },
]);

const tableHeader = computed(() => [
  { key: "type", label: t("common.labels.type") },
  { key: "date", label: t("common.labels.date") },
  { key: "title", label: t("common.labels.title") },
  { key: "status", label: t("common.labels.status") },
  { key: "price", label: t("common.labels.value") },
]);

const typeOptions = computed(
  () => tm("finance.enum.type") as Record<string, string>
);

const statusOptions = computed(
  () => tm("finance.enum.status") as Record<string, string>
);

const reportTypesOptions = computed(() => [
  {
    option: t("finance.enum.report_types.all"),
    value: "all",
  },
  {
    option: t("finance.enum.report_types.entrance"),
    value: "entrance",
  },
  {
    option: t("finance.enum.report_types.output"),
    value: "output",
  },
]);

const monthsOptions = computed(() => {
  const months = Object.entries(tm("date.months") as Record<string, string>)
    .map(([_, option], index) => ({
      option,
      value: index + 1,
    }));
  
  return [
    {
      option: t("finance.enum.report_types.all"),
      value: "all",
    },
    ...months,
  ];
});

const monthsList = computed(() => 
  Object.values(tm("date.months") as Record<string, string>));

const yearOptions = computed(() => {
  const currentYear = new Date().getFullYear();
  const yearsBefore = Array.from({ length: 5 }, (_, index) => index)
    .filter((_, index) => index > 0)
    .map((index) => currentYear - index)
    .sort((a, b) => a - b);

  const yearsAfter = Array.from({ length: 5 }, (_, index) => currentYear + index);

  return yearsBefore.concat(yearsAfter).map((year) => ({
    option: `${year}`,
    value: `${year}`,
  }));
});

const tableData = ref<FinanceType[]>([]);
const payload = ref<FinancePayloadType | null>(null);
const selected = ref<FinanceType | null>(null);

const chartData = ref([]);
const chartLabel = ref([]);
const chartMonth = ref("all");
const chartYear = ref();
const chartKey = ref(0);

const tabActive = ref<FinanceTypes>("all");

const reportData = ref<any>(null);
const reportStartDate = ref("");
const reportEndDate = ref("");
const reportType = ref("all");

const limit = ref<number>(10);
const page = ref<number>(0);
const pages = ref<number>(0);
const search = ref<string>("");
const searched = ref<number>(0);
const total = ref<number>(0);

const isEdit = ref(false);
const showModalAdd = ref(false);
const showConfirmDel = ref(false);
const payloadValid = ref(false);

function fetchData() {
  financeList(accountId.value, tabActive.value, {
    search: search.value,
    page: page.value,
    limit: limit.value,
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        tableData.value = data.list;
        page.value = data.infos.page;
        pages.value = data.infos.pages;
        total.value = data.infos.total;
        searched.value = data.infos.count;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function fetchReportData() {
  financeReport(
    accountId.value,
    reportType.value,
    reportStartDate.value,
    reportEndDate.value,
  )
    .then((response) => {
      const { success, data } = response;

      if (success) {
        reportData.value = data;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function searchItem(input: string) {
  search.value = input;
  fetchData();
}

function changePage(value: number) {
  page.value = value;
  fetchData();
}

function selectTab(tab: FinanceTypes) {
  if (tabActive.value === tab) return;

  tabActive.value = tab;

  if (tab === "report") {
    reportStartDate.value = moment().subtract(30, "days").format("YYYY-MM-DD");
    reportEndDate.value = moment().format("YYYY-MM-DD");
    fetchReportData();
  } 
  else if (tab === "charts") {
    fetchChartData();
  }
  else {
    fetchData();
  }
}

function add() {
  showModalAdd.value = true;
}

function addConfirm() {
  if (!payload.value || !payloadValid.value) return;

  showModalAdd.value = false;
  store.commit("SET_LOADING", true);

  financeAdd(accountId.value, {
    ...payload.value,
    date: moment(payload.value.date).format("YYYY-MM-DD"),
    price: currencyBRtoFloat(String(payload.value.price)),
  })
    .then((response) => {
      const { success, data } = response;
      fetchData();
      submitCancel();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function edit(data: FinanceType) {
  isEdit.value = true;
  showModalAdd.value = true;
  selected.value = { ...data };
}

function editConfirm() {
  if (!payload.value || !payloadValid.value || !selected.value) return;

  showModalAdd.value = false;
  store.commit("SET_LOADING", true);

  financePut(accountId.value, selected.value.finance_id, {
    ...payload.value,
    date: moment(payload.value.date).format("YYYY-MM-DD"),
    price: currencyBRtoFloat(String(payload.value.price)),
  })
    .then((response) => {
      const { success, data } = response;
      fetchData();
      submitCancel();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function submitCancel() {
  payload.value = null;
  selected.value = null;
  isEdit.value = false;
  showModalAdd.value = false;
}

function submitPayload() {
  if (isEdit.value) {
    editConfirm();
    return;
  }

  addConfirm();
}

function remove(data: FinanceType) {
  selected.value = data;
  showConfirmDel.value = true;
}

function removeCancel() {
  selected.value = null;
  showConfirmDel.value = false;
}

function removeConfirm() {
  if (!selected.value) return;

  showConfirmDel.value = false;
  store.commit("SET_LOADING", true);

  financeDel(accountId.value, selected.value.finance_id)
    .then((response) => {
      const { success, data } = response;
      fetchData();
      removeCancel();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function formatCurrency(item: any) {
  const amount =
    item.infos.initial_amount +
    item.infos.entrance.amount -
    item.infos.output.amount;

  const format = currencyFloatToBR(String(amount));

  const parsed = parseFloat(format);

  if (parsed < 0) return `- R$ ${format.replace("-", "")}`;

  return `R$ ${format}`;
}

function changeReportType() {
  fetchReportData();
}

function changeReportDate() {
  fetchReportData();
}

// CHARTS
function resumeChart(data: any) {
  chartLabel.value = [];
  chartData.value = [];

  if (data?.length === 0) {
    chartKey.value++;
    return;
  }

  const labels = [];
  const datasets = [
    {
      label: "Entrada",
      data: [],
      backgroundColor: "#000000",
    },
    {
      label: "Saída",
      data: [],
      backgroundColor: "#FDCB08",
    },
];

  Object.entries(data).forEach(([key, value]) => {
    const month = Number(key) - 1;
    const entranceAmount = value?.infos?.entrance?.amount;
    const outputAmount = value?.infos?.output?.amount;

    labels.push(monthsList.value[month]);
  
    datasets[0].data.push(entranceAmount);
    datasets[1].data.push(outputAmount);
  });

  chartLabel.value = labels;
  chartData.value = datasets;
  chartKey.value++;
}

function fetchChartData() {
  financeChart(
    accountId.value,
    chartMonth.value,
    chartYear.value,
  )
    .then((response) => {
      const { success, data } = response;

      if (success) {
        resumeChart(data.list);
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

onMounted(() => {
  fetchData();

  chartYear.value = String(moment().year());
});
</script>

<template>
  <panel-theme>
    <tab-menu
      :tabs="tabList"
      :active="tabActive"
      :tooltip="false"
      width="fit-content"
      padding-inline="1.5rem"
      @select:tab="selectTab"
    >
      <template #content>
        <template v-if="tabActive !== 'report' && tabActive !== 'charts'">
          <baseTable
            :header="tableHeader"
            :data="tableData"
            :limit="limit"
            :page="page"
            :pages="pages"
            :total="total"
            :searched="searched"
            :options="true"
            :edit="true"
            :del="true"
            @change:page="changePage"
            @searching="searchItem"
            @btn:add="add()"
            @btn:edit="edit"
            @btn:del="remove"
          >
            <template #status="{ data }">
              {{ statusOptions[data.status] }}
            </template>

            <template #title="{ data }">
              <div class="w-max-content">
                {{ data.title }}
              </div>
            </template>

            <template #date="{ data }">
              <div class="w-max-content">
                {{ moment(data.date).format("DD/MM/YYYY") }}
              </div>
            </template>

            <template #type="{ data }">
              {{ typeOptions[data.type] }}
            </template>

            <template #price="{ data }">
              {{ currencyFloatToBR(data.price) }}
            </template>
          </baseTable>
        </template>

        <template v-if="tabActive === 'report'">
          <div class="tab-report">
            <div class="header">
              <div class="flex-1">
                <base-select
                  v-model="reportType"
                  :value="reportType"
                  :options="reportTypesOptions"
                  :is-empty="false"
                  @change="changeReportType()"
                >
                  <template #label> {{ t("finance.labels.type") }}</template>
                </base-select>
              </div>

              <div class="flex-1">
                <base-date
                  v-model="reportStartDate"
                  :value="reportStartDate"
                  value-mask="YYYY-MM-DD"
                  placeholder="dd/mm/yyyy"
                  @update:modelValue="changeReportDate()"
                >
                  <template #label>
                    {{ t("finance.labels.start_date") }} *
                  </template>
                </base-date>
              </div>

              <div class="flex-1">
                <base-date
                  v-model="reportEndDate"
                  :value="reportEndDate"
                  value-mask="YYYY-MM-DD"
                  placeholder="dd/mm/yyyy"
                  @update:modelValue="changeReportDate()"
                >
                  <template #label>
                    {{ t("finance.labels.end_date") }} *
                  </template>
                </base-date>
              </div>
            </div>

            <div class="content">
              <div class="flex flex-col">
                <p>MOVIMENTAÇÃO FINANCEIRA</p>
                <table>
                  <tr>
                    <td class="uppercase">
                      {{ t("finance.labels.month") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.init") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.entrances") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.outputs") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.final") }}
                    </td>
                  </tr>
                  <template
                    v-for="(item, index) of reportData?.list"
                    :key="index"
                  >
                    <tr>
                      <td>
                        {{ item.infos.month }}
                      </td>
                      <td>
                        {{ item.infos.initial_total || 0 }}
                      </td>
                      <td>
                        {{ item.infos.entrance.total || 0 }}
                      </td>
                      <td>
                        {{ item.infos.output.total || 0 }}
                      </td>
                      <td>
                        {{ item.infos.initial_total + item.infos.entrance.total - item.infos.output.total }}
                      </td>
                    </tr>
                  </template>
                </table>
              </div>

              <div class="flex flex-col">
                <p>MOVIMENTAÇÃO FINANCEIRA EM R$</p>
                <table>
                  <tr>
                    <td class="uppercase">
                      {{ t("finance.labels.month") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.init") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.entrances") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.outputs") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.final") }}
                    </td>
                  </tr>
                  <template
                    v-for="(item, index) of reportData?.list"
                    :key="index"
                  >
                    <tr>
                      <td>
                        {{ item.infos.month }}
                      </td>
                      <td>
                        R$
                        {{
                          currencyFloatToBR(item.infos.initial_amount || "0.00")
                        }}
                      </td>
                      <td>
                        R$
                        {{
                          currencyFloatToBR(
                            item.infos.entrance.amount || "0.00"
                          )
                        }}
                      </td>
                      <td>
                        R$
                        {{
                          currencyFloatToBR(item.infos.output.amount || "0.00")
                        }}
                      </td>
                      <td>
                        {{ formatCurrency(item) }}
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </template>

        <template v-if="tabActive === 'charts'">
          <div class="tab-report">
            <div class="header">
              <div class="flex-1">
                <base-select
                  v-model="chartMonth"
                  :value="chartMonth"
                  :options="monthsOptions"
                  :is-empty="false"
                  @change="fetchChartData()"
                >
                  <template #label>
                    {{ t("date.labels.month") }}
                  </template>
                </base-select>
              </div>
        
              <div class="flex-1">
                <base-select
                  v-model="chartYear"
                  :value="chartYear"
                  :options="yearOptions"
                  :is-empty="false"
                  @change="fetchChartData()"
                >
                  <template #label>
                    {{ t("date.labels.year") }}
                  </template>
                </base-select>
              </div>
            </div>

            <div class="content">
              <div class="flex flex-col">
                <p>MOVIMENTAÇÃO FINANCEIRA</p>
                <chart-bars :key="chartKey" :labels="chartLabel" :data="chartData" />
              </div>
            </div>
          </div>
        </template>
      </template>
    </tab-menu>

    <base-modal
      :show="showModalAdd"
      :title="
        t('common.labels.add', {
          concat: t('common.labels.finance'),
        }).toUpperCase()
      "
      :show-button-confirm="true"
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="submitCancel()"
      @confirm="submitPayload()"
    >
      <template #content>
        <finance-form
          :data="selected"
          @update-data="payload = $event"
          @validate="payloadValid = $event"
        />
      </template>
    </base-modal>

    <base-confirm
      :show="showConfirmDel"
      :title="
        t('common.labels.remove', {
          concat: t('common.labels.finance'),
        }).toUpperCase()
      "
      :message="
        t('common.texts.remove_message', {
          concat: selected?.title,
        })
      "
      @cancel="removeCancel"
      @confirm="removeConfirm"
    />
  </panel-theme>
</template>

<style lang="scss" scoped>
.tab-report {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        padding: 0;
        border: 1px solid #ccc;

        td {
          border: none;
          border-right: 1px solid #ccc;
        }
      }

      td {
        padding: 0.5rem;
        border: 1px solid #ccc;
      }
    }
  }
}
</style>
