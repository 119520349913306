import clientAxios from "../client";

import { formatQueryString } from "@/common/utils/common";

import type { QueryType } from "@/common/types/common";
import type { ProductionType, ProductionPayloadType } from "@/common/types/production";

export async function productionList(
    account_id: string,
    query?: QueryType
) {
    const queryString = formatQueryString(query);

    return await clientAxios.get(
        `member/${account_id}/production/list${queryString}`
    );
}

export async function productionByID(
    account_id: string,
    production_id: string
) {
    return await clientAxios.get(
        `member/${account_id}/production/get/${production_id}`
    );
}

export async function productionAdd(
    account_id: string,
    payload: ProductionPayloadType
) {
    return await clientAxios.post(
        `member/${account_id}/production/add`,
        payload
    );
}

export async function productionPatch(
    account_id: string,
    production_id: string,
    payload: Partial<ProductionType>
) {
    return await clientAxios.patch(
        `member/${account_id}/production/${production_id}`,
        { payload }
    );
}

export async function productionUpdate(
    account_id: string,
    production_id: string,
    payload: ProductionType
) {
    return await clientAxios.put(
        `member/${account_id}/production/${production_id}/edit`,
        payload
    );
}

export async function productionFinish(
    account_id: string,
    production_id: string,
) {
    return await clientAxios.post(
        `member/${account_id}/production/${production_id}/finish`,
        null
    );
}

export async function productionCancel(
    account_id: string,
    production_id: string,
) {
    return await clientAxios.post(
        `member/${account_id}/production/${production_id}/cancel`,
        null
    );
}

export async function productionDel(
    account_id: string,
    production_id: string,
) {
    return await clientAxios.del(
        `member/${account_id}/production/${production_id}/del`
    );
}