import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import i18n from "./common/locales/i18n";
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/fluent-light/theme.css';
import 'primevue/resources/primevue.min.css';
import "vue3-toastify/dist/index.css";
import "./styles/styles.scss";

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.use(PrimeVue);

app.mount("#app");
