<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import {
  baseInput,
  baseSelect,
  baseTextarea,
  svgIcon,
} from "@/common/components";

import { TeamPayloadType, TeamTypeEnum, TeamType } from "@/common/types/team";

const { t } = useI18n();

const emit = defineEmits(["update-data"]);

const props = defineProps<{
  data?: TeamType | null;
  title?: string;
}>();

const defaultData = {
  active: true,
  description: "",
  email: "",
  name: "",
  type: "",
};

const teamTypeOptions = computed(() =>
  Object.values(TeamTypeEnum).map((value) => ({
    option: t(`team.enum.types.${value}`),
    value,
  }))
);

const statusOptions = computed(() => [
  {
    option: t("common.labels.active"),
    value: true,
  },
  {
    option: t("common.labels.inactive"),
    value: false,
  },
]);

const payload = ref<TeamPayloadType>({ ...defaultData });

const disabled = ref(false);

function update() {
  emit("update-data", payload.value);
}

watchEffect(async () => {
  if (props.data) {
    const { active, description, email, first_name, last_name, type } =
      props.data;

    payload.value = {
      active,
      description,
      email,
      name: `${first_name} ${last_name}`,
      type,
    };

    disabled.value = true;
  }
});
</script>

<template>
  <section class="team-form">
    <template v-if="title">
      <div class="title">
        <svg-icon icon="person" size="2.5rem" />
        {{ title }}
      </div>
    </template>

    <form>
      <div class="flex flex-row gap-2">
        <base-select
          v-model="payload.type"
          :value="payload.type"
          :options="teamTypeOptions"
          :required="true"
          :is-empty="false"
          @change="update()"
        >
          <template #label>
            {{ t("common.labels.type") }}
          </template>
        </base-select>

        <base-select
          v-model="payload.active"
          :value="payload.active"
          :options="statusOptions"
          :required="true"
          :is-empty="false"
          @change="update()"
        >
          <template #label>
            {{ t("common.labels.type") }}
          </template>
        </base-select>
      </div>

      <base-input
        v-model="payload.name"
        :value="payload.name"
        :placeholder="t('common.placeholders.name')"
        :disabled="disabled"
        :required="true"
        @blur="update()"
      >
        <template #label>
          {{ t("common.labels.name") }}
        </template>
      </base-input>

      <base-input
        v-model="payload.email"
        :value="payload.email"
        :placeholder="t('common.placeholders.email')"
        :disabled="disabled"
        :required="true"
        @blur="update()"
      >
        <template #label>
          {{ t("common.labels.email") }}
        </template>
        <template #sufix>
          <svg-icon icon="email" />
        </template>
      </base-input>

      <base-textarea
        v-model="payload.description"
        :value="payload.description"
        :placeholder="t('common.placeholders.describe')"
        @blur="update()"
      >
        <template #label>
          {{ t("common.labels.description") }}
        </template>
      </base-textarea>
    </form>
  </section>
</template>

<style lang="scss" scoped>
.team-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .title {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
}
</style>