import clientAxios from "../client";

import { formatQueryString } from "@/common/utils/common";

import type { QueryType } from "@/common/types/common";
import type { TeamType, TeamPayloadType } from "@/common/types/team";

export async function teamList(
    account_id: string,
    query?: QueryType
) {
    const queryString = formatQueryString(query);

    return await clientAxios.get(
        `member/${account_id}/team/list${queryString}`
    );
}

export async function teamFetchByID(
    account_id: string,
    team_id: string
) {
    return await clientAxios.get(
        `member/${account_id}/team/get/${team_id}`
    );
}

export async function teamAdd(
    account_id: string,
    payload: TeamPayloadType
) {
    return await clientAxios.post(
        `member/${account_id}/team/add`,
        payload
    );
}

export async function teamUpdate(
    account_id: string,
    team_id: string,
    payload: TeamPayloadType
) {
    return await clientAxios.put(
        `member/${account_id}/team/${team_id}/edit`,
        payload
    );
}

export async function teamDel(
    account_id: string,
    team_id: string,
) {
    return await clientAxios.del(
        `member/${account_id}/team/${team_id}/del`
    );
}