export const finance = {
    labels: {
        add_negotiation: "Adicionar negociação",
        type: "Tipo",
        start_date: "Data Inícial",
        end_date: "Data Final",
        month: "Mês",
        init: "Início",
        entrances: "Entradas",
        outputs: "Saídas",
        final: "Final",
        charts: "Gráficos",
    },
    placeholders: {},
    modals: {
        titles: {
            remove_negotiation: "Remover negociação",
        },
        messages: {
            remove_negotiation: "Você deseja remover esta <strong>NEGOCIAÇÃO</strong>?<br/>Esta ação não poderá ser desfeita!",
        },
    },
    enum: {
        status: {
            "PAID": "Faturado",
            "PENDING": "Em Aberto",
        },
        type: {
            "ENTRANCE": "Entrada",
            "OUTPUT": "Saída",
        },
        report_types: {
            all: "Exibir tudo",
            entrance: "Entrada",
            output: "Saída",
        },
    },
    texts: {}
};
