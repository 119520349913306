<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import trim from "lodash-es/trim";
import get from "lodash-es/get";

import { baseInput, baseLoading, svgIcon } from "@/common/components";

export interface TableHeaderType {
  key: string;
  label: string | null;
  align?: string;
}

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    header: TableHeaderType[];
    data: any[];
    page?: number;
    pages?: number;
    limit?: number;
    total?: number;
    searched?: number;
    options?: boolean;
    loading?: boolean;
    view?: boolean;
    edit?: boolean;
    del?: boolean;
    paginated?: boolean;
    add?: boolean;
    upload?: boolean;
    showSearch?: boolean;
  }>(),
  {
    page: 0,
    pages: 0,
    limit: 10,
    total: 0,
    searched: 0,
    options: false,
    view: false,
    edit: false,
    del: false,
    paginated: true,
    add: true,
    upload: false,
    showSearch: true,
  }
);

const emit = defineEmits([
  "searching",
  "btn:add",
  "btn:view",
  "btn:upload",
  "btn:edit",
  "btn:del",
  "change:page",
]);

const search = ref("");

const totalSearch = computed(() => {
  const { limit, page, searched, total } = props;
  const sum = (page + 1) * limit;
  if (page > 0) return sum < total ? sum : total;

  return searched;
});

function searchValue() {
  if (trim(search.value).length === 0) return;

  emit("searching", search.value);
}

function changePage(event: any) {
  const value = event?.target?.value || 0;
  const sanitize = String(value)?.replace(/\D/g, "");

  if (trim(sanitize) === "") {
    emit("change:page", 0);
    return;
  }

  if (parseInt(sanitize) < 0) {
    emit("change:page", 0);
    return;
  }

  if (parseInt(sanitize) >= props.pages) {
    emit("change:page", props.pages);
    return;
  }

  emit("change:page", parseInt(sanitize) - 1);
}

function nextPage() {
  const { page, pages } = props;

  if (page + 1 >= pages) return;

  emit("change:page", page + 1);
}

function beforePage() {
  const { page } = props;

  if (page <= 0) return;

  emit("change:page", page - 1);
}

function btnView(option: any) {
  emit("btn:view", option);
}

function btnEdit(option: any) {
  emit("btn:edit", option);
}

function btnDel(option: any) {
  emit("btn:del", option);
}

watch(
  () => search.value,
  () => {
    if (trim(search.value).length === 0) emit("searching", search.value);
  }
);
</script>

<template>
  <div class="base-table">
    <div
      class="header"
      :style="{
        gridTemplateColumns: add || upload ? '2fr auto' : '1fr',
      }"
    >
      <div class="search">
        <base-input
          v-if="showSearch"
          v-model="search"
          background="#fff"
          color="#000"
          :placeholder="t('common.labels.search')"
          @key:enter="searchValue()"
        >
          <template #sufix>
            <div class="icon">
              <svg-icon icon="search" size="1.75rem" @click="searchValue()" />
            </div>
          </template>
        </base-input>
      </div>
      <template v-if="add || upload">
        <div class="actions">
          <template v-if="upload">
            <button class="btn btn-md btn-primary" @click="emit('btn:upload')">
              <svg-icon icon="upload" size="1.5rem" />
              <span>
                {{ t("common.labels.upload")?.toUpperCase() }}
              </span>
            </button>
          </template>
          <template v-if="add">
            <button class="btn btn-md btn-primary" @click="emit('btn:add')">
              <svg-icon icon="add" size="1.5rem" />
              <span>
                {{ t("common.labels.add")?.toUpperCase() }}
              </span>
            </button>
          </template>
        </div>
      </template>
    </div>
    <template v-if="paginated">
      <div class="infos">
        <div class="total">
          {{ t("common.labels.showing") }} <strong>{{ totalSearch }}</strong> -
          <strong>{{ total || 0 }}</strong>
        </div>
        <div class="pages">
          {{ t("common.labels.page") }} <strong>{{ page + 1 }}</strong> -
          <strong>{{ pages }}</strong>
        </div>
      </div>
    </template>

    <div class="box-table">
      <table>
        <thead>
          <tr>
            <template v-for="item in header" :key="item.key">
              <th :style="{ 'text-align': item.align ? item.align : 'start' }">
                {{ item.label }}
              </th>
            </template>
            <th v-if="options"></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="loading">
            <tr>
              <td :colspan="((header || []).length || 1) + (options ? 1 : 0)">
                <base-loading />
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-for="(item, index) in data" :key="index">
              <tr>
                <template v-for="head in header" :key="head.key">
                  <template v-if="!$slots[head.key]">
                    <td>
                      {{ get(item, head.key, "") }}
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <slot :name="head.key" :data="item"></slot>
                    </td>
                  </template>
                </template>

                <td v-if="options" class="td-options">
                  <div class="options">
                    <template v-if="view">
                      <div class="icon">
                        <svg-icon
                          icon="eye"
                          size="1.75rem"
                          @click="btnView(item)"
                        />
                      </div>
                    </template>
                    <template v-if="edit">
                      <div class="icon">
                        <svg-icon
                          icon="edit"
                          size="1.75rem"
                          @click="btnEdit(item)"
                        />
                      </div>
                    </template>
                    <template v-if="del">
                      <div class="icon">
                        <svg-icon
                          icon="trash"
                          size="1.75rem"
                          @click="btnDel(item)"
                        />
                      </div>
                    </template>
                  </div>
                </td>
              </tr>
            </template>
            <template v-if="(data || []).length === 0">
              <tr>
                <td
                  class="empty"
                  :colspan="((header || []).length || 1) + (options ? 1 : 0)"
                >
                  {{ t("common.texts.no_results") }}
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>

    <div v-if="paginated" class="pagination">
      <div class="arrows">
        <div class="arrow">
          <svg-icon icon="arrow-left" size="1.15rem" @click="beforePage()" />
        </div>
        <input name="page" :value="page + 1" @change="changePage($event)" />
        <div class="arrow">
          <svg-icon icon="arrow-right" size="1.15rem" @click="nextPage()" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-table {
  display: flex;
  flex-direction: column;

  .box-table {
    width: 100%;
    overflow: auto;
  }

  table {
    width: 100%;
    border-spacing: 0 0.5rem;
    gap: 0.5rem;
    margin-block-start: 1rem;

    th {
      text-align: start;
      padding-inline: 0.75rem;
      padding-block: 3px;
      background: #fff;
      font-size: 0.875rem;
      font-weight: normal;
    }

    td {
      width: fit-content;
      text-align: start;
      padding-block: 0.5rem;
      padding-inline: 0.75rem;
      background: #f1f1f1;
      font-size: 0.95rem;

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }

    td.empty {
      padding-block: 10px;
      border-radius: 0.5rem;
    }
  }

  .header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    gap: 1rem;

    .search {
      .icon {
        cursor: pointer;
        color: var(--text-off);

        &:hover {
          color: var(--primary);
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 1.5rem;
    }
  }

  .infos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block-start: 1.5rem;
    padding-block-end: 0.7rem;
    padding-inline: 0.75rem;
    font-size: 0.95rem;
  }

  .td-options {
    width: 100px;
  }

  .options {
    display: flex;
    width: 100px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;

    .icon {
      cursor: pointer;
      user-select: none;
      width: 30px;
      color: var(--text-off);

      &:hover {
        color: var(--text);
      }
    }
  }

  .pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-block: 1rem;

    .arrows {
      display: grid;
      width: 150px;
      grid-template-columns: 30px 60px 30px;
      gap: 10px;
      align-items: center;
      justify-content: center;

      input {
        height: 30px;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
      }

      .arrow {
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .bg-white {
    background: #fff;
  }
}

@media screen and (max-width: 1024px) {
  .base-table {
    .header {
      grid-template-columns: 1fr auto;
      gap: 3rem;

      .actions {
        button {
          padding-inline: 0.75rem;

          span {
            display: none;
          }
        }
      }
    }
  }
}
</style>