export const common = {
    languages: {
        pt_br: "pt-br",
        en_us: "en-us",
    },
    labels: {
        account: "Account",
        accounts: "Accounts",
        active: "Ativo",
        address: "Address",
        addresses: "Addresses",
        add: "Add {concat}",
        administrator: "Administrator",
        alert: "Alert",
        amount: "Amount",
        analyse: "Analysis",
        analysis: "Analysis",
        apply: "Apply",
        assistant: "Assistant",
        assistants: "Assistants",
        avatar: "Avatar",
        back: "Back",
        bigger: "Bigger",
        birthdate: "Birthdate",
        brand: "Brand",
        calc: "Calc",
        calibration_date: "Calibration date",
        cancel: "Cancel {concat}",
        cep: "Zip code",
        certificate: "Certificate",
        certificates: "Certificates",
        change_account: "Change Account",
        city: "City",
        clean: "Clean",
        close: "Close",
        cnpj: "cnpj",
        cnae: "CNAE",
        cockpit: "Cockpit",
        code: "Code",
        company: "Company",
        companies: "Companies",
        complement: "Complement",
        canceled: "Canceled",
        canceleds: "Cancelds",
        concluded: "Concluded",
        condition: "Condition",
        conditions: "Conditions",
        confirm: "Confirm",
        confirm_password: "Confirm password",
        configs: "Settings",
        continue: "Continue",
        contact: "Contact",
        contacts: "Contacts",
        corporate_reason: "Corporate Reason",
        country: "Country",
        cpf: "cpf",
        crew: "Crew",
        deduct: "Deduct",
        date: "Date",
        dates: "Dates",
        description: "Description",
        details: "Details",
        delete: "Delete {concat}",
        district: "District",
        divide: "Divide",
        document: "Document",
        documents: "Documents",
        document_type: "Document type",
        download: "Download",
        edit: "Edit {concat}",
        equal: "Equal",
        equipment: "Equipment",
        equipments: "Equipment",
        email: "Email",
        employee: "Employee",
        employees: "Employees",
        enter: "Enter",
        entrance: "Entrance",
        exit: "Exit",
        expiration: "Expiration",
        expiration_date: "Expiration date",
        evaluation: "Evaluation",
        evaluations: "Evaluations",
        fabrication: "Manufacturing date",
        fantasy_name: "Fantasy Name",
        field: "Field",
        fields: "Fields",
        file: "File",
        files: "Files",
        finance: "Finance",
        finances: "Finances",
        finish: "Finish",
        form: "Form",
        forms: "Forms",
        footer: "Footer",
        gender: "Gender",
        group: "Group",
        groups: "Groups",
        home: "Home",
        if: "If",
        inactive: "Inactive",
        info: "Info",
        infos: "Infos",
        invoices: "Invoices",
        item: "Item",
        items: "Items",
        laboratory: "Laboratory",
        laboratories: "Laboratories",
        login: "Login",
        manager: "Manager",
        menu: "Menu",
        method: "Method",
        methods: "Methods",
        method_group: "Method Group",
        model: "Model",
        movement: "Movement",
        my_agenda: "My Agenda",
        my_account: "My Account",
        my_profile: "My Profile",
        name: "Name",
        new: "New {concat}",
        new_password: "New password",
        next: "Next",
        next_calibration: "Next Calibration",
        number: "Number",
        number_of_employee: "Number of Employee",
        not: "No",
        observation: "Observation",
        observations: "Observations",
        open: "Open",
        option: "Option",
        options: "Options",
        order: "Order",
        other: "Other",
        output: "Output",
        page: "Page",
        pages: "Pages",
        password: "Password",
        pending: "Pending",
        phone: "Phone",
        plans: "Plans",
        play: "Play {concat}",
        price: "Price",
        print: "Print",
        production: "Production",
        profile: "Profile",
        progress: "Progress",
        question: "Question",
        questions: "Questions",
        refresh: "Refresh",
        registration: "Registration",
        registrations: "Registrations",
        register: "Register",
        required: "Required",
        remove: "Remove {concat}",
        report: "Report",
        reports: "Reports",
        role: "Role",
        sample: "Sample",
        samples: "Samples",
        save: "Save",
        search: "Search",
        sector: "Sector",
        setting: "Setting",
        settings: "Settings",
        send: "Send",
        serial_number: "Serial Number",
        showing: "Showing",
        signature: "Signature",
        sign: "Sign",
        smaller: "Smaller",
        social_name: "Social Name",
        start: "Start",
        started: "Started",
        state: "State",
        status: "Status",
        stop: "Stop",
        stock: "Stock",
        subscriptions: "Subscriptions",
        subtitle: "Subtitle",
        sum: "Sum",
        summary: "Summary",
        team: "Team",
        technical: "Technical",
        technicals: "Technicians",
        termination: "Termination",
        title: "Title",
        titles: "Titles",
        time: "Time",
        total_duration: "Total duration",
        total: "Total",
        type: "Type",
        unit: "Unit",
        upload: "Upload {concat}",
        user: "User",
        users: "Users",
        validity: "Validity",
        visible: "Visible",
        value: "Value",
        yes: "Yes",
    },
    placeholders: {
        code: "Enter the code",
        describe: "Describe",
        email: "Enter your email",
        login: "Enter your login",
        name: "Enter name",
        title: "Enter title",
        order: "Enter order",
        password: "Enter your Password",
        select: "Select...",
    },
    modals: {
        titles: {
            remove_avatar: "Remover Avatar",
        },
        messages: {
            remove_avatar: "Você deseja remover o <strong>AVATAR</strong>?<br/>Esta ação não poderá ser desfeita!"
        },
    },
    enums: {
        gender: {
            male: "Male",
            female: "Female",
            other: "Other",
        }
    },
    texts: {
        no_results: "There are no results to display.",
        forgot_password: "Forgot your password?",
        recover_password: "Recover Password!",
        already_account: "Already account?",
        dont_account: "Dont account?",
        register_account: "Register account",
        remembered_password: "Did you remember your password?",
        undo_message: "This operation cannot be undone!",
        item_message: "Added items will also be lost!",
        remove_message: "Do you want to remove <strong>''{concat}''</strong>?<br/>This action cannot be undone!",
        input_code: "Enter the code below that was sent to the registered email:",
        file_upload: "Select or capture the file to send",
        model_download: "Download model file",
    }
};