<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import { toast } from "@/common/utils/toast";
import { maskNumber } from "@/common/utils/mask";

import { svgIcon, baseInput, baseSelect } from "@/common/components";

import { stockList } from "@/common/api/rest/stock";

import type { StockType } from "@/common/types/stock";

const { t, tm } = useI18n();

const emit = defineEmits(["update-data", "is:valid"]);

const props = defineProps<{
  title?: string;
  accountId?: string | null;
  status?: string | null;
  type?: string | null;
  isMovement?: boolean;
}>();

const listData = ref<StockType[]>([]);
const selected = ref<string | null>(null);
const selectedData = ref<StockType | null>(null);

const inputSearch = ref<string | null>("");
const inputType = ref("ENTRANCE");
const inputAmount = ref("1");
const inputUnit = ref<string>(null);

const searching = ref(false);
const isValid = ref(false);

const statusEnum = computed(
  () => tm("stock.enum.status") as Record<string, string>
);

const unitShortEnum = computed(
  () => tm("stock.enum.units_short") as Record<string, string>
);

const typesEnum = computed(
  () => tm("stock.enum.types") as Record<string, string>
);

const typeOptions = computed(() => [
  {
    option: t("common.labels.entrance"),
    value: "ENTRANCE",
  },
  {
    option: t("common.labels.output"),
    value: "OUTPUT",
  },
]);

const unitOptions = computed(() =>
  Object.entries(tm("stock.enum.units_name") as Record<string, string>).map(
    ([value, option]) => ({
      option,
      value,
    })
  )
);

function searchData() {
  if (!props.accountId) return;

  stockList(props.accountId, {
    search: inputSearch.value as string,
    type: props.type ?? null,
    status: props.status ?? null,
    limit: 500,
    page: 0,
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        listData.value = data.list;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function search() {
  searching.value = !!inputSearch.value;

  searchData();
}

function checkValid(data: StockType) {
  if (inputType.value === "ENTRANCE") return true;

  return Number.parseFloat(inputAmount.value) <= data.amount;
}

function updateAmount() {
  isValid.value = checkValid(selectedData.value);
  emit("update-data", {
    type: inputType.value,
    stock: selectedData.value,
    amount: Number.parseFloat(inputAmount.value),
  });
  emit("is:valid", isValid.value);
}

function select(data: StockType) {
  if (selected.value === data.stock_id) {
    selected.value = null;
    selectedData.value = null;
    inputType.value = "ENTRANCE";
    inputAmount.value = "1";
    emit("update-data", null);
    searchData();
    return;
  }

  selectedData.value = data;
  selected.value = data.stock_id;
  inputUnit.value = data.unit_name;
  listData.value = [data];

  updateAmount();
  emit("update-data", {
    stock: data,
    type: inputType.value,
    amount: inputAmount.value,
  });
}

watchEffect(() => {
  if (!props.isMovement) inputType.value = "OUTPUT";
});
</script>

<template>
  <section class="search-component">
    <div class="title">
      {{ title }}
    </div>

    <div class="search">
      <template v-if="selected">
        <div class="flex-1">
          <base-select
            v-model="inputType"
            :value="inputType"
            :options="typeOptions"
            :is-empty="false"
            :disabled="!props.isMovement"
            @change="updateAmount()"
          >
            <template #label> {{ t("common.labels.unit") }}</template>
          </base-select>
        </div>

        <div class="flex-3">
          <base-input
            v-model="inputAmount"
            :value="inputAmount"
            :mask="maskNumber"
            type="number"
            min="1"
            @input="updateAmount()"
          >
            <template #label>
              {{ t("common.labels.amount") }}
            </template>
          </base-input>
        </div>

        <div class="flex-1">
          <base-select
            v-model="inputUnit"
            :value="inputUnit"
            :options="unitOptions"
            :disabled="true"
          >
            <template #label> {{ t("common.labels.unit") }}</template>
          </base-select>
        </div>
      </template>

      <template v-else>
        <base-input
          v-model="inputSearch"
          background="#fff"
          color="#000"
          :placeholder="t('common.labels.search')"
          @key:enter="search()"
        >
          <template #sufix>
            <div class="icon">
              <svg-icon icon="search" size="1.75rem" @click="search()" />
            </div>
          </template>
        </base-input>
      </template>
    </div>

    <div class="list">
      <template v-for="(item, index) in listData" :key="index">
        <div
          class="item"
          :class="{
            active: selected === item.stock_id,
            error:
              (!selected &&
                inputType !== 'ENTRANCE' &&
                item.status !== 'IN_STOCK') ||
              (selected && inputType !== 'ENTRANCE' && !isValid),
          }"
          @click="select(item)"
        >
          <div class="name">{{ item.code }} - {{ item.name }}</div>
          <div class="line">
            <span>{{ t("common.labels.type") }}: </span>
            <strong>{{ typesEnum[item.type] }}</strong>
          </div>
          <div class="line">
            <span>{{ t("common.labels.status") }}: </span>
            <strong>{{ statusEnum[item.status] }}</strong>
          </div>
          <div class="line">
            <span>{{ t("common.labels.amount") }}: </span>
            <strong>
              {{ item.amount || 0 }} {{ unitShortEnum[item.unit_name] }}
            </strong>
          </div>
        </div>
      </template>
      <template v-if="searching && listData.length === 0">
        <div class="empty">
          {{ t("common.texts.no_results") }}
        </div>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.search-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;

  .title {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .search {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;

    .icon {
      cursor: pointer;
      color: var(--text-off);

      &:hover {
        color: var(--primary);
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    width: calc(100% - 0.5rem);
    height: 16rem;
    overflow-y: auto;
    padding-inline: 0.5rem;
    gap: 0.5rem;

    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border: 1px solid var(--border);
      padding-block: 0.5rem;
      padding-inline: 1rem;
      border-radius: 0.5rem;

      &.active {
        border-color: var(--primary);
        background: var(--primary);
        color: var(--primary-color);
      }

      &.error {
        border-color: var(--red);
        background: var(--white);
        color: var(--text-off);
      }

      .name {
        font-weight: bold;
      }

      .line {
        font-size: 0.875rem;
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .box-add {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-block-start: 1.5rem;
    }
  }
}
</style>