import clientAxios from "../client";

import type { BuyPlanType } from "@/common/types/plan";

export async function listPlans() {
    return await clientAxios.get(
        `common/plans`
    );
}

export async function getPlan(
    plan_id: string
) {
    return await clientAxios.get(
        `common/plan/${plan_id}`
    );
}

export async function buyPlan(
    payload: BuyPlanType
) {
    return await clientAxios.post(
        `common/plan/buy`,
        payload
    );
}