export const team = {
    labels: {
        add_team: "Adicionar usuário",
    },
    enum: {
        types: {
            admin: "Administrador",
            manager: "Gestor",
            member: "Membro",
        },
    },
    texts: {
        add_team: "Adicionar usuário",
    }
};
