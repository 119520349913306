<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import moment from "moment";

import Calendar from "primevue/calendar";

const props = withDefaults(
  defineProps<{
    width?: string;
    height?: string;
    radius?: string;
    background?: string;
    placeholder?: string;
    value: string | null;
    disabled?: boolean;
    mask?: "dd/mm/yy" | "yy/mm/dd";
    valueMask: string;
  }>(),
  {
    width: "100%",
    height: "2.5rem",
    radius: "0.375rem",
    background: "#fff",
    mask: "dd/mm/yy",
  }
);

const emit = defineEmits(["update:modelValue", "select-date", "key:enter"]);

const internalValue = ref<Date | null>(null);

const maskFormat = computed(() =>
  props.mask ? props.mask?.replace("yy", "YYYY")?.toUpperCase() : "DD/MM/YYYY"
);

function update(value: any) {
  const date = moment(value, maskFormat.value).format("YYYY-MM-DD");

  emit("update:modelValue", date);
}

watchEffect(() => {
  if (!props.value) {
    internalValue.value = null;
  }

  if (props.value && props.valueMask) {
    const valueFormat = moment(props.value, props.valueMask).format(
      maskFormat.value
    );

    internalValue.value = valueFormat;
  }
});
</script>

<template>
  <div class="base-input" :class="{ disabled: disabled }">
    <div v-if="$slots.label" class="label">
      <slot name="label" />
    </div>
    <div class="content" :class="{ error: $slots.error }">
      <div v-if="$slots.prefix" class="prefix">
        <slot name="prefix" />
      </div>
      <div class="input" :class="{ pref: $slots.prefix, suf: $slots.sufix }">
        <Calendar
          class="calendar"
          v-model="internalValue"
          :dateFormat="props.mask"
          :placeholder="props.placeholder"
          :manualInput="false"
          showIcon
          fluid
          iconDisplay="input"
          @date-select="update($event)"
        />
      </div>
      <div v-if="$slots.sufix" class="sufix">
        <slot name="sufix" />
      </div>
    </div>
    <div v-if="$slots.error" class="error-message">
      <slot name="error" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$width: v-bind("width");
$height: v-bind("height");
$radius: v-bind("radius");
$background: v-bind("background");

.base-input.disabled {
  .content {
    background: var(--white-off);
  }
}

.base-input {
  display: flex;
  flex-direction: column;
  width: $width;
  gap: 2px;

  .label {
    font-size: 0.875rem;
    margin-block-end: 0.2rem;
    margin-inline: 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: calc(100% - (1px * 2));
    height: calc($height + 4px);
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: $radius;
    background: $background;

    &.error {
      border-color: red;
    }
  }

  .error-message {
    text-align: end;
    font-size: 0.8rem;
    padding-inline: $radius;
    color: red;
  }

  .prefix,
  .sufix {
    user-select: none;
    display: flex;
    flex-direction: column;
    width: $height;
    height: $height;
    align-items: center;
    justify-content: center;
  }

  .input {
    flex: 2;
    padding-inline: 5px;

    &.pref {
      padding-inline-start: 0;
    }

    &.suf {
      padding-inline-end: 0;
    }

    .p-calendar {
      width: 100%;
      height: calc($height - 4px);
      background: transparent;
      padding-inline: 10px;
      outline: none;
      border: none;
      font-size: 1rem;
      border: 0;
    }

    :deep(.p-inputtext) {
      border: none;
      outline: none;
      box-shadow: none;
      font-size: 1rem;
      font-weight: normal;
      padding: 0;
      color: var(--text);
    }
  }
}
</style>