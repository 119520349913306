import axios from "axios";

export const get = async (
    route: string,
    headers?: any
) => {
    return axios
        .get(route, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

export const patch = async (
    route: string,
    payload: any,
    headers?: any
) => {
    return await axios
        .patch(route, payload, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

export const post = async (
    route: string,
    payload: any,
    headers?: any
) => {
    return await axios
        .post(route, payload, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

export const put = async (
    route: string,
    payload: any,
    headers?: any
) => {
    return axios
        .put(route, payload, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

export const del = async (
    route: string,
    headers?: any
) => {
    return axios
        .delete(route, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
