import {
    ProductionStatusEnum,
    ProductionAreaEnum,
    ProductionSearchStatusEnum,
} from "@/common/types/production";

export const production = {
    labels: {
        area: "Área",
        area_type: "Tipo de Área",
        culture: "Cultura",
        planting: "Plantio",
        harvest: "Colheita",
        description: "Descrição",
        add_production: "Adicionar produção",
        price: "Preço",
        finish: "Finalizar",
        cancel: "Cancelar",
        add_culture: "Adicionar Cultura",
        stock_consumption: "Consumo estoque",
        negotiations: "Negociações",
        amount_used: "Quantidade utilizada",
        unit_name: "Unidade tipo",
    },
    placeholders: {
        area: "exe: 100,00"
    },
    modals: {
        titles: {
            add_culture: "Adicionar Cultura",
            add_consumption: "Adicionar Consumo",
            finish_production: "Finalizar Produção",
            cancel_production: "Cancelar Produção",
        },
        messages: {
            finish_production: "Você deseja <strong>FINALIZAR</strong> esta produção?<br/> Essa operação não poderá ser desfeita!",
            cancel_production: "Você deseja <strong>CANCELAR</strong> esta produção?<br/> Essa operação não poderá ser desfeita!",
        },
    },
    enum: {
        search_status: {
            [ProductionSearchStatusEnum.ALL]: "Todos",
            [ProductionSearchStatusEnum.IN_PROGRESS]: "Em Andamento",
            [ProductionSearchStatusEnum.CONCLUDED]: "Finalizados",
            [ProductionSearchStatusEnum.CANCELED]: "Cancelados",
        },
        area: {
            [ProductionAreaEnum.ACRES]: "Acres",
            [ProductionAreaEnum.HECTARE]: "Hectares",
            [ProductionAreaEnum.METERS]: "Metros²",
            [ProductionAreaEnum.KILOMETERS]: "Quilômetros²",
        },
        status: {
            [ProductionStatusEnum.PREPARATION]: "Preparação",
            [ProductionStatusEnum.PLANTING]: "Plantio",
            [ProductionStatusEnum.HARVEST]: "Colheita",
            [ProductionStatusEnum.NEGOTIATION]: "Negociação",
            [ProductionStatusEnum.SOLD]: "Vendido",
            "CANCELED": "Cancelado",
        },
    },
    texts: {
    }
};
