export interface TeamType {
    team_id: string
    member_id: string
    type: string
    active: boolean
    first_name: string
    last_name: string
    social_name: string | null
    email: string
    description: string | null
    created: string
    updated: string | null
}

export interface TeamPayloadType {
    active: boolean
    description: string | null
    email: string
    name: string
    type: string
}

export enum TeamTypeEnum {
    ADMIN = 'admin',
    MANAGER = "manager",
    MEMBER = 'member'
}