export const plan = {
    labels: {
        affiliate_accounts: "Contas Afiliadas",
        plan: "Plano",
        plans: "Planos",
        invoice: "Fatura",
        invoices: "Faturas",
        my_account: "Minha Conta",
        my_plans: "Meus Planos",
        my_signature: "Minha Assinatura",
        my_invoices: "Minhas Faturas",
    },
    texts: {
        account_empty: "Você ainda não tem uma conta.",
        affiliate_accounts: "Você ainda não tem acesso a outras contas.",
        invoice_empty: "Você ainda não tem faturas.",
        signature_empty: "Você ainda não tem uma assinatura.",
    },
};
