<script setup lang="ts">
import { ref, watchEffect } from "vue";

const props = withDefaults(
  defineProps<{
    width?: string;
    height?: string;
    radius?: string;
    background?: string;
    value?: string | number | null;
    disabled?: boolean;
    mask?: (value: string) => void;
  }>(),
  {
    width: "100%",
    height: "2.5rem",
    radius: "0.375rem",
    background: "#fff",
  }
);

const emit = defineEmits(["update:modelValue", "key:enter"]);

const internalValue = ref<string | number | null>(null);

function update(event: any) {
  let value = event.target.value;
  if (props.mask) value = props.mask(value);

  internalValue.value = value;
  emit("update:modelValue", internalValue.value);
}

watchEffect(() => {
  if (props.value !== undefined) internalValue.value = props.value;
});
</script>

<template>
  <div class="base-input" :class="{ disabled: disabled }">
    <div v-if="$slots.label" class="label">
      <slot name="label" />
    </div>
    <div class="content" :class="{ error: $slots.error }">
      <div v-if="$slots.prefix" class="prefix">
        <slot name="prefix" />
      </div>
      <div class="input" :class="{ pref: $slots.prefix, suf: $slots.sufix }">
        <input
          v-bind="$attrs"
          v-model="internalValue"
          autocomplete="off"
          :disabled="disabled"
          @input="update"
          @keydown.enter="emit('key:enter')"
        />
      </div>
      <div v-if="$slots.sufix" class="sufix">
        <slot name="sufix" />
      </div>
    </div>
    <div v-if="$slots.error" class="error-message">
      <slot name="error" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$width: v-bind("width");
$height: v-bind("height");
$radius: v-bind("radius");
$background: v-bind("background");

.base-input.disabled {
  .content {
    background: var(--white-off);
  }
}

.base-input {
  display: flex;
  flex-direction: column;
  width: $width;
  gap: 2px;

  .label {
    font-size: 0.875rem;
    margin-block-end: 0.2rem;
    margin-inline: 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: calc(100% - (1px * 2));
    height: calc($height + 4px);
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: $radius;
    background: $background;

    &.error {
      border-color: red;
    }
  }

  .error-message {
    text-align: end;
    font-size: 0.8rem;
    padding-inline: $radius;
    color: red;
  }

  .prefix,
  .sufix {
    user-select: none;
    display: flex;
    flex-direction: column;
    width: $height;
    height: $height;
    align-items: center;
    justify-content: center;
  }

  .input {
    flex: 2;
    padding-inline: 5px;

    &.pref {
      padding-inline-start: 0;
    }

    &.suf {
      padding-inline-end: 0;
    }

    input {
      width: calc(100% - (10px * 2));
      height: calc($height - 4px);
      background: transparent;
      padding-inline: 10px;
      outline: none;
      font-size: 1rem;
      border: 0;
    }
  }
}
</style>