<script setup lang="ts">
import store from "@/store";
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { userProfile, userProfilePatch } from "@/common/api/rest/user";
import { avatarAdd, avatarPut, avatarDel } from "@/common/api/rest/avatar";

import { toast } from "@/common/utils/toast";
import { DefaultTheme } from "@/common/themes";

import {
  baseAvatar,
  baseConfirm,
  baseInput,
  baseSelect,
  baseTextarea,
  svgIcon,
} from "@/common/components";

const { t, tm } = useI18n();

const DEFAULT_PAYLOAD = {
  full_name: "",
  social_name: "",
  avatar: null,
  gender: null,
  birthdate: "",
  description: "",
};

const profile = ref({ ...DEFAULT_PAYLOAD });

const showAvatarDel = ref(false);

const genderOptions = computed(
  () =>
    Object.entries(tm("common.enums.gender") as Record<string, string>).map(
      ([value, option]) => ({
        option,
        value,
      })
    ) as { option: string; value: any }[]
);

function fetchProfile() {
  if (!store.getters.user) return;

  userProfile(store.getters.user.member_id)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        const {
          avatar,
          birthdate,
          first_name,
          last_name,
          social_name,
          gender,
          description,
        } = data;

        profile.value = {
          avatar,
          birthdate,
          full_name: (first_name || "") + (last_name ? ` ${last_name}` : ""),
          gender,
          social_name,
          description,
        };

        store.commit("SET_PROFILE", data);
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function update(field: string) {
  if (!store.getters.user) return;

  let payload = { [field]: profile.value[field] };

  if (field === "full_name") {
    const names = profile.value[field]?.split(" ");
    const first_name = names[0];
    const last_name = names.slice(1).join(" ");

    payload = { first_name, last_name };
  }

  userProfilePatch(store.getters.user.member_id, payload)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        fetchProfile();

        toast({ message: data.message, type: "success" });
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function addAvatar(avatar: string) {
  if (!store.getters.user) return;

  store.commit("SET_LOADING", true);

  avatarAdd(store.getters.user.member_id, avatar)
    .then((response) => {
      const { success, data } = response;

      fetchProfile();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function updateAvatar(avatar: string) {
  if (!profile.value.avatar) return;

  store.commit("SET_LOADING", true);

  avatarPut(
    store.getters.user.member_id,
    profile.value.avatar.avatar_id,
    avatar
  )
    .then((response) => {
      const { success, data } = response;

      fetchProfile();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function changeAvatar(avatar: string) {
  if (profile.value.avatar) {
    updateAvatar(avatar);
  } else {
    addAvatar(avatar);
  }
}

function delAvatar() {
  showAvatarDel.value = true;
}

function delAvatarConfirm() {
  if (!profile.value?.avatar) return;

  store.commit("SET_LOADING", true);

  avatarDel(store.getters.user.member_id, profile.value.avatar?.avatar_id)
    .then((response) => {
      const { success, data } = response;

      fetchProfile();

      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      showAvatarDel.value = false;
      store.commit("SET_LOADING", false);
    });
}

onMounted(() => {
  fetchProfile();
});
</script>

<template>
  <default-theme>
    <section class="profile-page">
      <div class="box">
        <div class="title">
          <svg-icon icon="person" size="2.5rem" />
          {{ t("common.labels.profile") }}
        </div>

        <div class="content">
          <form>
            <div class="flex justify-center">
              <base-avatar
                position="center"
                :is-edit="true"
                :show-image="true"
                :url="profile.avatar?.large"
                @change="changeAvatar($event)"
                @del="delAvatar()"
              />
            </div>

            <base-input
              v-model="profile.full_name"
              :value="profile.full_name"
              :placeholder="t('common.placeholders.name')"
              :required="true"
              :max-length="50"
              @blur="update('full_name')"
            >
              <template #label> {{ t("common.labels.name") }} * </template>
            </base-input>

            <base-input
              v-model="profile.social_name"
              :value="profile.social_name"
              :placeholder="t('common.placeholders.name')"
              :required="true"
              :max-length="50"
              @blur="update('social_name')"
            >
              <template #label>
                {{ t("common.labels.social_name") }}
              </template>
            </base-input>

            <base-select
              v-model="profile.gender"
              :value="profile.gender"
              :options="genderOptions"
              :is-empty="false"
              @change="update('gender')"
            >
              <template #label> {{ t("common.labels.gender") }}</template>
            </base-select>

            <base-textarea
              v-model="profile.description"
              :value="profile.description"
              :placeholder="t('common.placeholders.describe')"
              @blur="update('description')"
            >
              <template #label>
                {{ t("common.labels.description") }}
              </template>
            </base-textarea>
          </form>
        </div>
      </div>
    </section>

    <base-confirm
      :show="showAvatarDel"
      :title="t('common.modals.titles.remove_avatar').toUpperCase()"
      :message="t('common.modals.messages.remove_avatar')"
      @cancel="showAvatarDel = false"
      @confirm="delAvatarConfirm()"
    />
  </default-theme>
</template>

<style lang="scss" scoped>
.profile-page {
  display: flex;
  flex-direction: column;
  gap: 1.54rem;
  padding-block-end: 2rem;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.375rem;
    gap: 1rem;

    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
</style>
