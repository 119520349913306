import store from "@/store";
import { decrypt, encrypt } from "../utils/crypt";

import type { UserType } from "@/common/types/user";

export async function setUser(data: UserType): Promise<boolean> {
    try {
        const session = process.env.VUE_APP_USER_SESSION ?? "";
        const encryptData = encrypt(JSON.stringify(data));

        localStorage.setItem(session, encryptData ?? "");

        store.commit("SET_USER", data);
        store.commit("SET_LOGGED", true);

        return true;
    } catch (_) {
        return false;
    }
}

export function getUser(): UserType | null {
    try {
        const session = process.env.VUE_APP_USER_SESSION ?? "";
        const data = localStorage.getItem(session);
        const decrypted = decrypt(data);
        if (decrypted) {
            const dataParse: UserType = JSON.parse(decrypted);
            if (dataParse.token) {
                return dataParse;
            }
        }
        return null;
    } catch (_) {
        return null;
    }
}

export function removeUser() {
    const session = process.env.VUE_APP_USER_SESSION ?? "";
    localStorage.removeItem(session);

    store.commit("SET_LOADING", true);
    store.commit("SET_USER", null);
    store.commit("SET_AVATAR", null);
    store.commit("SET_PROFILE", null);
    store.commit("SET_LOGGED", false);
    store.commit("SET_LOADING", false);

    const publicRoutes = [
        "/auth/login",
        "/auth/recover",
        "/auth/recover/valid",
        "/auth/register",
    ];

    const currentRoute = window.location.pathname;

    if (publicRoutes.includes(currentRoute))
        return;

    window.location.href = "/auth/login";
}
