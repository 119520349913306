<script setup lang="ts">
import { computed, ref } from "vue";
import { svgIcon } from "@/common/components";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import store from "@/store";

import { removeUser } from "@/common/services/user";

import { baseAvatarUser } from "@/common/components";

const { t, tm } = useI18n();
const router = useRouter();

withDefaults(
  defineProps<{
    height?: string;
    background?: string;
  }>(),
  {
    height: "4rem",
    background: "#fff",
  }
);

const showMenu = ref(false);

const types = computed(() => tm("technical.types") as Record<string, string>);

const type = computed(() => store.getters.type ? types.value[store.getters.type] : "");

function goTo(url: string) {
  showMenu.value = false;
  router.push(url);
}

function logout() {
  removeUser();
}
</script>

<template>
  <header class="mobile-header">
    <section class="brand">
      <img :src="require('@/assets/images/logo-secondary.png')" />
    </section>

    <section class="user">
      <base-avatar-user
        width="11rem"
        size="2.2rem"
        font-size="0.75rem"
        :name="$store.getters.profile?.first_name"
        :role="type"
        :open="showMenu"
        @click="showMenu = !showMenu"
      />
    </section>

    <template v-if="showMenu">
      <div class="box-menu">
        <div class="menu-content">
          <div class="item" @click="goTo('/accounts')">
            <svg-icon icon="change" size="1.75rem" />
            {{ t("common.labels.change_account") }}
          </div>
          <div class="item" @click="goTo('/profile')">
            <svg-icon icon="person" size="1.75rem" />
            {{ t("common.labels.my_profile") }}
          </div>
          <div class="item" @click="logout()">
            <svg-icon icon="exit" size="1.75rem" />
            {{ t("common.labels.exit") }}
          </div>
        </div>
      </div>
    </template>
  </header>
</template>

<style lang="scss" scoped>
$height: v-bind("height");
$background: v-bind("background");

.mobile-header {
  position: relative;
  z-index: 97;
  display: flex;
  flex-direction: row;
  background: $background;
  box-shadow: 3px 2px 5px var(--shadow);
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;

  .brand {
    display: flex;
    flex-direction: column;
    height: $height;
    justify-content: center;

    img {
      width: 9.43rem;
      height: 2.5rem;
    }
  }

  .user {
    display: flex;
  }

  .box-menu {
    position: absolute;
    right: 1rem;
    top: calc($height + 0.5rem);
    width: 10rem;
    background: var(--white);
    box-shadow: -3px 2px 5px var(--shadow);
    border: 1px solid var(--border);
    border-radius: 0.3rem;
    z-index: 0;

    .menu-content {
      display: flex;
      flex-direction: column;
      padding-block: 0.5rem;
      gap: 0.5rem;

      .item {
        user-select: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
        padding-inline: 0.75rem;
        padding-block: 0.5rem;
        color: var(--text);

        &:hover {
          background: var(--primary);
          color: var(--primary-color);
        }
      }
    }
  }
}
</style>
