import moment from "moment";

export function getHour(date?: string | null, sec = true) {
    if (!date)
        return "--:--";

    const formattedDate = moment(date);
    const format = sec ? 'HH:mm:ss' : 'HH:mm';

    return formattedDate.format(format);
}

export function getDate(date?: string | null, format = "DD/MM/YYYY", response = "--/--/----") {
    if (!date)
        return response;

    const formattedDate = moment(date).format(format);

    return formattedDate;
}

export function getDateISO(date: string) {
    if (!date)
        return "";

    const formattedDate = moment(date, "DD/MM/YYYY").format('YYYY-MM-DDTHH:mm:ss');

    return formattedDate;
}

export function getWeek(date: string | null) {
    if (!date)
        return "";

    const formattedDate = moment(date);
    const dayOfWeek = formattedDate.format('dddd');

    return dayOfWeek.toLowerCase();
}

export function getDifference(startDate: string, endDate: string, showSeconds= true) {
    const start = moment(startDate);
    const end = moment(endDate);

    const years = end.diff(start, 'years');
    start.add(years, 'years');

    const months = end.diff(start, 'months');
    start.add(months, 'months');

    const days = end.diff(start, 'days');
    start.add(days, 'days');

    const hours = end.diff(start, 'hours');
    start.add(hours, 'hours');

    const minutes = end.diff(start, 'minutes');
    start.add(minutes, 'minutes');

    const seconds = end.diff(start, 'seconds');
    const secondsText = showSeconds ? ` ${seconds}s` : "";

    let text = `${minutes}m${secondsText}`;
    if (hours > 0)
        text = `${hours}h ${minutes}m${secondsText}`;
    if (days > 0)
        text = `${days}d ${hours}h ${minutes}m${secondsText}`;
    if (months > 0)
        text = `${months}m ${days}d ${hours}h ${minutes}m${secondsText}`;

    return {
        years,
        months,
        days,
        hours,
        minutes,
        seconds,
        text,
    };
}

export function getDiffMinutes(startDate: string | null, endDate: string | null) {
    if (!startDate || !endDate) return "";

    const start = moment(startDate);
    const end = moment(endDate);

    return end.diff(start, 'minutes');
}
