<script setup lang="ts">
import { ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { svgIcon } from "@/common/components";

const router = useRouter();

const props = withDefaults(
  defineProps<{
    menus: { icon: string; label: string; url: string; disabled?: boolean }[];
    open?: boolean;
    width?: string;
    background?: string;
    active?: boolean;
  }>(),
  {
    open: false,
    width: "4.5rem",
    background: "#fff",
    active: true,
  }
);

const emit = defineEmits(["open"]);

const currentRoute = computed(() => router.currentRoute.value.path);

const opened = ref(false);
const tooltip = ref(-1);

function toggleOpen() {
  opened.value = !opened.value;
  emit("open", opened.value);
}

function goToUrl(data: {
  icon: string;
  label: string;
  url: string;
  disabled?: boolean;
}) {
  if (!data.url || data.disabled) return;

  router.push(data.url);
}

watchEffect(() => {
  opened.value = props.open;
});
</script>

<template>
  <nav class="side-menu">
    <section class="button" @click="toggleOpen()">
      <svg-icon icon="bars" size="1.875rem" />
    </section>
    <section class="menus">
      <template v-for="(menu, index) in menus" :key="index">
        <div
          class="item"
          :class="{
            active: currentRoute.includes(menu.url),
            disabled: menu.disabled,
          }"
          @click="goToUrl(menu)"
          @mouseover="tooltip = index"
          @mouseleave="tooltip = -1"
        >
          <div class="icon">
            <svg-icon :icon="menu.icon" size="1.875rem" />
          </div>
          <div v-if="tooltip === index" class="tooltip">
            {{ menu.label }}
          </div>
        </div>
      </template>
    </section>
  </nav>
</template>

<style lang="scss" scoped>
$width: v-bind("width");
$background: v-bind("background");

.side-menu {
  display: flex;
  flex-direction: column;
  width: $width;
  height: 100%;
  background: $background;

  .button {
    user-select: none;
    cursor: pointer;
    display: flex;
    width: $background;
    height: 4rem;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    background: #333333;
    box-shadow: 1px 2px 5px var(--shadow);
  }

  .menus {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1.25rem;
    padding-block: 1.57rem;

    .item {
      position: relative;
      cursor: pointer;
      user-select: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc($width * 0.6);
      height: calc($width * 0.6);
      color: var(--text-off);
      border-radius: 0.32rem;
      box-shadow: 1px 2px 5px var(--shadow);

      .icon {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &:hover,
      &.active {
        color: var(--primary);
        background: #333333;
      }

      &.disabled {
        cursor: default;
        color: var(--text-off);
        background: var(--white-off);
      }

      .tooltip {
        position: absolute;
        z-index: 5;
        width: max-content;
        margin-inline-start: calc($width * 0.6 + 10px);
        background: white;
        color: #000;
        box-shadow: 1px 2px 5px var(--shadow);
        padding-block: 0.1rem;
        padding-inline: 0.25rem;
        border-radius: 0.25rem;
        font-size: 0.9rem;
        border: 1px solid #e7e7e7;
      }
    }
  }
}
</style>