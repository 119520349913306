<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { toast } from "@/common/utils/toast";

import { temperatureList } from "@/common/api/rest/temperature";

import type { TemperatureType } from "@/common/types/temperature";

const { t } = useI18n();

const props = defineProps<{
  city: string;
}>();

const internalCity = ref(null);
const internalData = ref<TemperatureType | null>(null);

const condition = computed(() => {
  if (!internalData.value) return;

  const night = internalData.value.condition_slug.includes("night");

  return night
    ? `${internalData.value.condition_code}n`
    : internalData.value.condition_code;
});

async function fetchData() {
  temperatureList()
    .then((response) => {
      const { success, data } = response;

      if (success) {
        internalData.value = data.results;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

onMounted(() => {
  internalCity.value = props.city;
  fetchData();
});
</script>

<template>
  <section class="card-weather">
    <template v-if="internalData">
      <div class="header">
        <div class="location">
          <div class="city">
            {{ internalData.city }}
          </div>
          <div class="sunrise">
            {{ internalData.sunrise }}
          </div>
          <div class="sunset">
            {{ internalData.sunset }}
          </div>
        </div>
        <div class="icon">
          <template v-if="condition">
            <img :src="require(`@/assets/conditions/${condition}.png`)" />
          </template>
        </div>
        <div class="temperature">
          <div class="temp">{{ internalData.temp }}º C</div>
          <div class="description">
            {{ internalData.description }}
          </div>
        </div>
      </div>
      <div class="forecast">
        <template v-for="(item, index) in internalData.forecast" :key="index">
          <div
            class="item"
            :class="{
              border: index + 1 < internalData.forecast.length,
            }"
          >
            <div class="day">{{ item.weekday }} - {{ item.date }}</div>
            <div class="icon">
              <img
                :src="require(`@/assets/conditions/${item.condition}.svg`)"
              />
            </div>
            <div class="temperature">
              <div class="min">{{ item.min }}º C</div>
              <div class="max">{{ item.max }}º C</div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.card-weather {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border);

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);

    .location,
    .icon,
    .temperature {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }

    .city,
    .temp {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .forecast {
    display: flex;
    flex-direction: row;
    overflow-x: auto;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.875rem;
      padding-block: 0.5rem;
      min-width: 90px;

      img {
        width: 3rem;
      }

      &.border {
        border-right: 1px solid var(--border);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .card-weather {
    .header {
      flex-direction: column;
      padding-block: 1.25rem;
      gap: 1rem;

      .icon img {
        width: 10rem;
      }
    }
  }
}
</style>