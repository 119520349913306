import { get, post, put, patch, del } from "./rest";
import { getUser } from "@/common/services/user";

const server = process.env.VUE_APP_API ?? "";

const headers = () => {
    const language = localStorage.getItem("locale") || process.env.VUE_APP_LANGUAGE;
    const user = getUser();

    return {
        "Content-Type": "application/json",
        "language": language,
        "id": user?.member_id,
        "token": user?.token,
    }
}

class clientAxios {
    async get(url: string, isParams = true) {
        return await get(`${server}/${url}`, isParams ? headers() : {});
    }

    async post(url: string, data: any) {
        return await post(`${server}/${url}`, data, headers());
    }

    async put(url: string, data: any) {
        return await put(`${server}/${url}`, data, headers());
    }

    async patch(url: string, data: any) {
        return await patch(`${server}/${url}`, data, headers());
    }

    async del(url: string, isParams = true) {
        return await del(`${server}/${url}`, isParams ? headers() : {});
    }
}

export default new clientAxios();