export const user = {
    types: {
        admin: "Administrador",
        manager: "Gestor",
        technical: "Técnico",
    },
    labels: {
        uninformed: "Não Informado",
    },
};
