<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";

import { toast } from "@/common/utils/toast";
import { currencyBRtoFloat } from "@/common/utils/mask";

import { productionAdd, productionList } from "@/common/api/rest/production";

import { ProductionSearchStatusEnum } from "@/common/types/production";
import type {
  ProductionType,
  ProductionPayloadType,
} from "@/common/types/production";

import { PanelTheme } from "@/common/themes";
import { CardProduction, productionForm } from "@/components";
import {
  baseConfirm,
  baseInput,
  baseModal,
  baseSelect,
  baseTable,
  svgIcon,
} from "@/common/components";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const accountID = computed(() => route.params.id as string);

const productions = ref<ProductionType[]>([]);
const payload = ref<ProductionPayloadType | null>(null);
const selected = ref<ProductionType | null>(null);
const status = ref(ProductionSearchStatusEnum.IN_PROGRESS);

const search = ref<string>(null);
const limit = ref<number>(10);
const page = ref<number>(0);

const showModalForm = ref(false);
const limited = ref(false);

const searchStatusOptions = computed(() =>
  Object.values(ProductionSearchStatusEnum).map((value) => ({
    option: t(`production.enum.search_status.${value}`),
    value,
  }))
);

function goToProduction(production_id: string) {
  if (!production_id) return;

  router.push(`/${accountID.value}/production/${production_id}`);
}

async function fetchData() {
  await productionList(accountID.value, {
    limit: limit.value,
    page: page.value,
    search: search.value,
    status: status.value,
  })
    .then((response) => {
      const { success, data } = response;

      if (!success) {
        toast({ message: data.message, type: "error" });
        return;
      }
      productions.value = data.list;
      page.value = data.infos.page;
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function searchValue() {
  fetchData();
}

function addProduction() {
  if (limited.value) {
    toast({ message: "Limite atingido", type: "info" });
    return;
  }

  showModalForm.value = true;
}

function addProductionCancel() {
  selected.value = null;
  showModalForm.value = false;
}

function addProductionConfirm() {
  if (!payload.value) return;

  store.commit("SET_LOADING", true);

  productionAdd(accountID.value, {
    ...payload.value,
    area: currencyBRtoFloat(String(payload.value.area)),
    price: currencyBRtoFloat(String(payload.value.price)),
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        addProductionCancel();
        goToProduction(data.production_id);
        toast({ message: data.message, type: "success" });
      } else {
        showModalForm.value = true;
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function checkValue() {
  if (search.value.trim().length === 0) fetchData();
}

onMounted(async () => {
  store.commit("SET_LOADING", true);
  await fetchData();
  store.commit("SET_LOADING", false);
});
</script>

<template>
  <panel-theme>
    <article class="production-page">
      <section class="header box">
        <div class="title">
          <svg-icon icon="plant" size="2.5rem" />
          {{ t("common.labels.production") }}
        </div>

        <div class="actions">
          <base-select
            v-model="status"
            :value="status"
            :options="searchStatusOptions"
            :required="true"
            :is-empty="false"
            @change="searchValue()"
          />

          <base-input
            v-model="search"
            background="#fff"
            color="#000"
            :placeholder="t('common.labels.search')"
            @update:model-value="checkValue()"
            @key:enter="searchValue()"
          >
            <template #sufix>
              <div class="icon">
                <svg-icon icon="search" size="1.75rem" @click="searchValue()" />
              </div>
            </template>
          </base-input>

          <div />

          <button class="btn btn-md btn-primary" @click="addProduction()">
            <svg-icon icon="add" size="1.5rem" />
            <span>
              {{ t("common.labels.add")?.toUpperCase() }}
            </span>
          </button>
        </div>
      </section>

      <section class="list">
        <template v-for="item in productions" :key="item.production_id">
          <card-production
            :data="item"
            @click="goToProduction(item.production_id)"
          />
        </template>
      </section>
    </article>

    <base-modal
      :show="showModalForm"
      :show-button-confirm="true"
      :title="t('production.labels.add_production').toUpperCase()"
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="addProductionCancel()"
      @confirm="addProductionConfirm()"
    >
      <template #content>
        <production-form :data="selected" @update-data="payload = $event" />
      </template>
    </base-modal>
  </panel-theme>
</template>

<style lang="scss" scoped>
.production-page {
  display: flex;
  flex-direction: column;
  gap: 1.54rem;

  .header {
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: space-between;
    gap: 1rem;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--text-medium);
    }

    .actions {
      display: grid;
      grid-template-columns: 1fr 2fr 2rem auto;
      gap: 1rem;

      .icon {
        cursor: pointer;
        color: var(--text-off);

        &:hover {
          color: var(--text);
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .box {
    width: inherit;
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.25rem;
  }
}
</style>
